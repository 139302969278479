var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"org-info org-version\">\n            <a href=\"#about-zulip\" class=\"navigate-link-on-enter popover-menu-link\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "version_display_string", {"start":{"line":7,"column":86},"end":{"line":7,"column":108}} ), depth0))
    + "</a>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"server_needs_upgrade"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":13,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"org-info org-upgrade\">\n            <a href=\"https://zulip.readthedocs.io/en/stable/production/upgrade.html\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Upgrade to the latest release",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":176},"end":{"line":11,"column":214}}}))
    + "</a>\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"org-info org-plan hidden-for-spectators\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_plan_limited"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":24,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "            <a href=\"/plans/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">Zulip Cloud Free</a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_plan_standard"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":24,"column":12}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "            <a href=\"/plans/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">Zulip Cloud Standard</a>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_plan_standard_sponsored_for_free"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":24,"column":12}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return "            <a href=\"/plans/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">Zulip Cloud Standard (sponsored)</a>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_plan_plus"),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":24,"column":12}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    return "            <a href=\"/plans/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">Zulip Cloud Plus</a>\n            ";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"sponsorship_pending"),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":49,"column":15}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"org-info org-upgrade\">\n            <a href=\"/sponsorship/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Sponsorship request pending",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":127},"end":{"line":30,"column":163}}}))
    + "</a>\n        </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_plan_limited"),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":37,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_org_on_paid_plan"),{"name":"unless","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":48,"column":19}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"org-info org-upgrade\">\n            <a href=\"/upgrade/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Upgrade to {standard_plan_name}",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":123},"end":{"line":35,"column":163}}}))
    + "</a>\n        </div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_education_org"),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":39,"column":8},"end":{"line":47,"column":15}}})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"org-info org-upgrade\">\n            <a href=\"/sponsorship/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Request education pricing",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":127},"end":{"line":41,"column":161}}}))
    + "</a>\n        </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"is_business_org"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":43,"column":18},"end":{"line":43,"column":39}}}),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":8},"end":{"line":47,"column":8}}})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"org-info org-upgrade\">\n            <a href=\"/sponsorship/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Request sponsorship",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":127},"end":{"line":45,"column":155}}}))
    + "</a>\n        </div>\n        ";
},"28":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n            <a role=\"menuitem\" href=\"#groups/your\" class=\"navigate-link-on-enter popover-menu-link\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-user-cog\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Group settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":70,"column":49},"end":{"line":70,"column":72}}}))
    + "</span>\n            </a>\n        </li>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n            <a role=\"menuitem\" href=\"/stats\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-bar-chart\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Usage statistics",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":84,"column":49},"end":{"line":84,"column":74}}}))
    + "</span>\n            </a>\n        </li>\n";
},"32":function(container,depth0,helpers,partials,data) {
    return "checked";
},"34":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" href=\"/billing/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-credit-card\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Billing",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":135,"column":49},"end":{"line":135,"column":65}}}))
    + "</span>\n            </a>\n        </li>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" href=\"https://zulip.com/help/support-zulip-project\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-heart\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Support Zulip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":143,"column":49},"end":{"line":143,"column":71}}}))
    + "</span>\n            </a>\n        </li>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" href=\"/self-hosted-billing/\" id=\"open-self-hosted-billing\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-rocket\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Plan management",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":151,"column":49},"end":{"line":151,"column":73}}}))
    + "</span>\n            </a>\n        </li>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" href=\"/plans/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-rocket\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Plans and pricing",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":160,"column":49},"end":{"line":160,"column":75}}}))
    + "</span>\n            </a>\n        </li>\n";
},"42":function(container,depth0,helpers,partials,data) {
    return "        <li role=\"separator\" class=\"popover-menu-separator\"></li>\n";
},"44":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" tabindex=\"0\" class=\"invite-user-link popover-menu-link\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-user-plus\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Invite users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":172,"column":49},"end":{"line":172,"column":70}}}))
    + "</span>\n            </a>\n        </li>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Grant Zulip the Kerberos tickets needed to run your Zephyr mirror via Webathena",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":177,"column":72},"end":{"line":177,"column":160}}}))
    + "\" id=\"webathena_login_menu\">\n            <a role=\"menuitem\" href=\"#webathena\" class=\"webathena_login popover-menu-link\">\n                <i class=\"popover-menu-icon fa fa-bolt\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Link with Webathena",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":180,"column":49},"end":{"line":180,"column":77}}}))
    + "</span>\n            </a>\n        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu\" id=\"gear-menu-dropdown\" aria-labelledby=\"settings-dropdown\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <div class=\"org-info-container\">\n        <div class=\"org-info org-name\">"
    + alias3(alias2(alias1(depth0, "realm_name", {"start":{"line":3,"column":41},"end":{"line":3,"column":51}} ), depth0))
    + "</div>\n        <div class=\"org-info org-url\">"
    + alias3(alias2(alias1(depth0, "realm_url", {"start":{"line":4,"column":40},"end":{"line":4,"column":49}} ), depth0))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_self_hosted"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":26,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"and").call(alias4,lookupProperty(helpers,"not").call(alias4,lookupProperty(depth0,"is_self_hosted"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":27,"column":19},"end":{"line":27,"column":39}}}),lookupProperty(depth0,"user_has_billing_access"),lookupProperty(helpers,"not").call(alias4,lookupProperty(depth0,"is_plan_standard_sponsored_for_free"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":27,"column":64},"end":{"line":27,"column":105}}}),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":27,"column":14},"end":{"line":27,"column":106}}}),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":8},"end":{"line":50,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <ul role=\"menu\" class=\"popover-menu-list\">\n        <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n            <a role=\"menuitem\" href=\"#channels/subscribed\" class=\"navigate-link-on-enter popover-menu-link\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-hash\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Channel settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":57,"column":49},"end":{"line":57,"column":74}}}))
    + "</span>\n            </a>\n        </li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item admin-menu-item hidden-for-spectators\">\n            <a role=\"menuitem\" href=\"#organization\" class=\"navigate-link-on-enter popover-menu-link\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-building\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Organization settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":49},"end":{"line":63,"column":79}}}))
    + "</span>\n            </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"is_guest"),{"name":"unless","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":8},"end":{"line":73,"column":19}}})) != null ? stack1 : "")
    + "        <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n            <a role=\"menuitem\" href=\"#settings\" class=\"navigate-link-on-enter popover-menu-link\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-tool\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Personal settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":77,"column":49},"end":{"line":77,"column":75}}}))
    + "</span>\n            </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"is_guest"),{"name":"unless","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":8},"end":{"line":87,"column":19}}})) != null ? stack1 : "")
    + "        <li role=\"none\" class=\"popover-menu-list-item only-visible-for-spectators\">\n            <div role=\"group\" class=\"theme-switcher tab-picker\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"App theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":89,"column":76},"end":{"line":89,"column":94}}}))
    + "\">\n                <input type=\"radio\" id=\"select-automatic-theme\" class=\"tab-option\" name=\"theme-select\" data-theme-code=\""
    + alias3(alias2(alias1(lookupProperty(lookupProperty(depth0,"color_scheme_values"),"automatic"), "code", {"start":{"line":90,"column":122},"end":{"line":90,"column":156}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"user_color_scheme"),lookupProperty(lookupProperty(lookupProperty(depth0,"color_scheme_values"),"automatic"),"code"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":90,"column":166},"end":{"line":90,"column":223}}}),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":160},"end":{"line":90,"column":239}}})) != null ? stack1 : "")
    + " />\n                <label role=\"menuitemradio\" class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\"select-automatic-theme\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Select automatic theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":91,"column":140},"end":{"line":91,"column":171}}}))
    + "\" data-tooltip-template-id=\"automatic-theme-template\" tabindex=\"0\">\n                    <i class=\"zulip-icon zulip-icon-monitor\" aria-hidden=\"true\"></i>\n                </label>\n                <input type=\"radio\" id=\"select-light-theme\" class=\"tab-option\" name=\"theme-select\" data-theme-code=\""
    + alias3(alias2(alias1(lookupProperty(lookupProperty(depth0,"color_scheme_values"),"light"), "code", {"start":{"line":94,"column":118},"end":{"line":94,"column":148}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"user_color_scheme"),lookupProperty(lookupProperty(lookupProperty(depth0,"color_scheme_values"),"light"),"code"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":94,"column":158},"end":{"line":94,"column":211}}}),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":152},"end":{"line":94,"column":227}}})) != null ? stack1 : "")
    + " />\n                <label role=\"menuitemradio\" class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\"select-light-theme\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Select light theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":95,"column":136},"end":{"line":95,"column":163}}}))
    + "\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Light theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":95,"column":185},"end":{"line":95,"column":205}}}))
    + "\" tabindex=\"0\">\n                    <i class=\"zulip-icon zulip-icon-sun\" aria-hidden=\"true\"></i>\n                </label>\n                <input type=\"radio\" id=\"select-dark-theme\" class=\"tab-option\" name=\"theme-select\" data-theme-code=\""
    + alias3(alias2(alias1(lookupProperty(lookupProperty(depth0,"color_scheme_values"),"dark"), "code", {"start":{"line":98,"column":117},"end":{"line":98,"column":146}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"user_color_scheme"),lookupProperty(lookupProperty(lookupProperty(depth0,"color_scheme_values"),"dark"),"code"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":98,"column":156},"end":{"line":98,"column":208}}}),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":150},"end":{"line":98,"column":224}}})) != null ? stack1 : "")
    + " />\n                <label role=\"menuitemradio\" class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\"select-dark-theme\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Select dark theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":99,"column":135},"end":{"line":99,"column":161}}}))
    + "\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Dark theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":99,"column":183},"end":{"line":99,"column":202}}}))
    + "\" tabindex=\"0\">\n                    <i class=\"zulip-icon zulip-icon-moon\" aria-hidden=\"true\"></i>\n                </label>\n                <span class=\"slider\"></span>\n            </div>\n        </li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item only-visible-for-spectators\">\n            <a role=\"menuitem\" tabindex=\"0\" class=\"change-language-spectator popover-menu-link\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-f-globe\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Select language",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":108,"column":49},"end":{"line":108,"column":73}}}))
    + "</span>\n            </a>\n        </li>\n        <li role=\"separator\" class=\"popover-menu-separator\"></li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" href=\""
    + alias3(alias2(alias1(depth0, "apps_page_url", {"start":{"line":114,"column":40},"end":{"line":114,"column":53}} ), depth0))
    + "\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-monitor\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Desktop & mobile apps",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":116,"column":49},"end":{"line":116,"column":79}}}))
    + "</span>\n            </a>\n        </li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" href=\"/integrations/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-git-pull-request\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Integrations",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":122,"column":49},"end":{"line":122,"column":70}}}))
    + "</span>\n            </a>\n        </li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" href=\"/api/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-file-text\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"API documentation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":128,"column":49},"end":{"line":128,"column":75}}}))
    + "</span>\n            </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"show_billing"),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":131,"column":8},"end":{"line":138,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"promote_sponsoring_zulip"),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":139,"column":8},"end":{"line":146,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"show_remote_billing"),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":147,"column":8},"end":{"line":154,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"show_plans"),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":155,"column":8},"end":{"line":163,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"or").call(alias4,lookupProperty(depth0,"can_invite_users_by_email"),lookupProperty(depth0,"can_create_multiuse_invite"),lookupProperty(depth0,"is_spectator"),lookupProperty(depth0,"show_webathena"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":165,"column":14},"end":{"line":165,"column":99}}}),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":165,"column":8},"end":{"line":167,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"or").call(alias4,lookupProperty(depth0,"can_invite_users_by_email"),lookupProperty(depth0,"can_create_multiuse_invite"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":168,"column":14},"end":{"line":168,"column":71}}}),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":168,"column":8},"end":{"line":175,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"show_webathena"),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":176,"column":8},"end":{"line":183,"column":15}}})) != null ? stack1 : "")
    + "        <li role=\"none\" class=\"link-item popover-menu-list-item only-visible-for-spectators\">\n            <a role=\"menuitem\" href=\""
    + alias3(alias2(alias1(depth0, "login_link", {"start":{"line":185,"column":39},"end":{"line":185,"column":49}} ), depth0))
    + "\" class=\"navigate-link-on-enter popover-menu-link\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-log-in\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Log in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":187,"column":49},"end":{"line":187,"column":64}}}))
    + "</span>\n            </a>\n        </li>\n    </ul>\n</div>\n";
},"useData":true});