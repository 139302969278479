var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"sent_by_me"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"sent_by_me"),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":12,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"edit_content message_control_button\"></div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"reaction_button message_control_button\" data-tooltip-template-id=\"add-emoji-tooltip-template\">\n            <div class=\"emoji-message-control-button-container\">\n                <i class=\"message-controls-icon zulip-icon zulip-icon-smile\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Add emoji reaction",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":89},"end":{"line":9,"column":116}}}))
    + " (:)\" role=\"button\" aria-haspopup=\"true\" tabindex=\"0\"></i>\n            </div>\n        </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "";
},"8":function(container,depth0,helpers,partials,data) {
    return "empty-star";
},"10":function(container,depth0,helpers,partials,data) {
    return "unstar";
},"12":function(container,depth0,helpers,partials,data) {
    return "star";
},"14":function(container,depth0,helpers,partials,data) {
    return "zulip-icon-star-filled";
},"16":function(container,depth0,helpers,partials,data) {
    return "zulip-icon-star";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_archived"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "\n<div class=\"actions_hover message_control_button\" data-tooltip-template-id=\"message-actions-tooltip-template\" >\n    <i class=\"message-controls-icon message-actions-menu-button zulip-icon zulip-icon-more-vertical-spread\" role=\"button\" aria-haspopup=\"true\" tabindex=\"0\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Message actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":168},"end":{"line":16,"column":192}}}))
    + "\"></i>\n</div>\n\n<div class=\"star_container message_control_button "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"starred"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":19,"column":50},"end":{"line":19,"column":94}}})) != null ? stack1 : "")
    + "\" data-tooltip-template-id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"starred"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":19,"column":122},"end":{"line":19,"column":166}}})) != null ? stack1 : "")
    + "-message-tooltip-template\">\n    <i role=\"button\" tabindex=\"0\" class=\"message-controls-icon star zulip-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"starred"),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":20,"column":79},"end":{"line":20,"column":150}}})) != null ? stack1 : "")
    + "\"></i>\n</div>\n";
},"useData":true});