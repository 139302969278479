var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "active-sub-filter";
},"3":function(container,depth0,helpers,partials,data) {
    return "zero-topic-unreads";
},"5":function(container,depth0,helpers,partials,data) {
    return "muted_topic";
},"7":function(container,depth0,helpers,partials,data) {
    return "unmuted_or_followed_topic";
},"9":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"unread_mention_info\">\n                    @\n                </span>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_followed"),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":16,"column":12}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <i class=\"zulip-icon zulip-icon-follow visibility-policy-icon\" role=\"button\" aria-hidden=\"true\" data-tippy-content=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"You follow this topic.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":132},"end":{"line":15,"column":162}}}))
    + "\"></i>\n            ";
},"14":function(container,depth0,helpers,partials,data) {
    return "zero_count";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"bottom_left_row "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_active_topic"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":27},"end":{"line":1,"column":74}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_zero"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":75},"end":{"line":1,"column":115}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_muted"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":116},"end":{"line":1,"column":150}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_unmuted_or_followed"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":151},"end":{"line":1,"column":213}}})) != null ? stack1 : "")
    + " topic-list-item\" data-topic-name=\""
    + alias4(alias3(alias2(depth0, "topic_name", {"start":{"line":1,"column":250},"end":{"line":1,"column":260}} ), depth0))
    + "\">\n    <div class=\"topic-box\">\n        <span class=\"sidebar-topic-check\">\n            "
    + alias4(alias3(alias2(depth0, "topic_resolved_prefix", {"start":{"line":4,"column":14},"end":{"line":4,"column":35}} ), depth0))
    + "\n        </span>\n        <a href=\""
    + alias4(alias3(alias2(depth0, "url", {"start":{"line":6,"column":19},"end":{"line":6,"column":22}} ), depth0))
    + "\" class=\"sidebar-topic-name\">\n            <span class=\"sidebar-topic-name-inner\">"
    + alias4(alias3(alias2(depth0, "topic_display_name", {"start":{"line":7,"column":53},"end":{"line":7,"column":71}} ), depth0))
    + "</span>\n        </a>\n        <div class=\"topic-markers-and-unreads change_visibility_policy\" data-stream-id=\""
    + alias4(alias3(alias2(depth0, "stream_id", {"start":{"line":9,"column":90},"end":{"line":9,"column":99}} ), depth0))
    + "\" data-topic-name=\""
    + alias4(alias3(alias2(depth0, "topic_name", {"start":{"line":9,"column":122},"end":{"line":9,"column":132}} ), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"contains_unread_mention"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":16,"column":19}}})) != null ? stack1 : "")
    + "            <span class=\"unread_count "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_zero"),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":38},"end":{"line":17,"column":70}}})) != null ? stack1 : "")
    + "\">\n                "
    + alias4(alias3(alias2(depth0, "unread", {"start":{"line":18,"column":18},"end":{"line":18,"column":24}} ), depth0))
    + "\n            </span>\n        </div>\n        <span class=\"sidebar-menu-icon topic-sidebar-menu-icon\">\n            <i class=\"zulip-icon zulip-icon-more-vertical\" aria-hidden=\"true\"></i>\n        </span>\n    </div>\n</li>\n";
},"useData":true});