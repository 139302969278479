var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias1(container.lambda(container.strict(depth0, "content", {"start":{"line":1,"column":2},"end":{"line":1,"column":9}} ), depth0))
    + "\n"
    + alias1(lookupProperty(helpers,"tooltip_hotkey_hints").call(depth0 != null ? depth0 : (container.nullContext || {}),"S",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":28}}}))
    + "\n";
},"useData":true});