var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../settings/group_setting_value_pill_input.hbs"),undefined,{"name":"../settings/group_setting_value_pill_input","hash":{"prefix":lookupProperty(depth0,"prefix"),"label":lookupProperty(helpers,"t").call(alias1,"Who can administer this group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":43}}}),"setting_name":"can_manage_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("../settings/group_setting_value_pill_input.hbs"),undefined,{"name":"../settings/group_setting_value_pill_input","hash":{"prefix":lookupProperty(depth0,"prefix"),"label":lookupProperty(helpers,"t").call(alias1,"Who can mention this group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":40}}}),"setting_name":"can_mention_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("../settings/group_setting_value_pill_input.hbs"),undefined,{"name":"../settings/group_setting_value_pill_input","hash":{"prefix":lookupProperty(depth0,"prefix"),"label":lookupProperty(helpers,"t").call(alias1,"Who can add members to this group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":13,"column":47}}}),"setting_name":"can_add_members_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("../settings/group_setting_value_pill_input.hbs"),undefined,{"name":"../settings/group_setting_value_pill_input","hash":{"prefix":lookupProperty(depth0,"prefix"),"label":lookupProperty(helpers,"t").call(alias1,"Who can remove members from this group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":18,"column":52}}}),"setting_name":"can_remove_members_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("../settings/group_setting_value_pill_input.hbs"),undefined,{"name":"../settings/group_setting_value_pill_input","hash":{"prefix":lookupProperty(depth0,"prefix"),"label":lookupProperty(helpers,"t").call(alias1,"Who can join this group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":23,"column":37}}}),"setting_name":"can_join_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("../settings/group_setting_value_pill_input.hbs"),undefined,{"name":"../settings/group_setting_value_pill_input","hash":{"prefix":lookupProperty(depth0,"prefix"),"label":lookupProperty(helpers,"t").call(alias1,"Who can leave this group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":28,"column":38}}}),"setting_name":"can_leave_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});