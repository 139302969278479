var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "zero-dm-unreads";
},"3":function(container,depth0,helpers,partials,data) {
    return "zero_count";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li id=\"show-more-direct-messages\" class=\"dm-list-item dm-box bottom_left_row "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"more_conversations_unread_count"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":78},"end":{"line":1,"column":147}}})) != null ? stack1 : "")
    + "\">\n    <a class=\"dm-name\" tabindex=\"0\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"more conversations",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":36},"end":{"line":2,"column":63}}}))
    + "</a>\n    <span class=\"unread_count "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"more_conversations_unread_count"),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":30},"end":{"line":3,"column":94}}})) != null ? stack1 : "")
    + "\">\n        "
    + alias2(container.lambda(container.strict(depth0, "more_conversations_unread_count", {"start":{"line":4,"column":10},"end":{"line":4,"column":41}} ), depth0))
    + "\n    </span>\n</li>\n";
},"useData":true});