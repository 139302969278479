var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"emoji_row\" id=\"emoji_"
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":2,"column":34},"end":{"line":2,"column":38}} ), depth0))
    + "\">\n    <td>\n        <span class=\"emoji_name\">"
    + alias3(alias2(alias1(depth0, "display_name", {"start":{"line":4,"column":35},"end":{"line":4,"column":47}} ), depth0))
    + "</span>\n    </td>\n    <td>\n        <span class=\"emoji_image\">\n            <a href=\""
    + alias3(alias2(alias1(depth0, "source_url", {"start":{"line":8,"column":23},"end":{"line":8,"column":33}} ), depth0))
    + "\" target=\"_blank\" rel=\"noopener noreferrer\">\n                <img class=\"emoji\" src=\""
    + alias3(alias2(alias1(depth0, "source_url", {"start":{"line":9,"column":42},"end":{"line":9,"column":52}} ), depth0))
    + "\" alt=\""
    + alias3(alias2(alias1(depth0, "display_name", {"start":{"line":9,"column":63},"end":{"line":9,"column":75}} ), depth0))
    + "\" />\n            </a>\n        </span>\n    </td>\n    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"author"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":20,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n    <td>\n        <button class=\"button rounded small delete button-danger tippy-zulip-delayed-tooltip\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"can_delete_emoji"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":94},"end":{"line":23,"column":152}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":173},"end":{"line":23,"column":188}}}))
    + "\" data-emoji-name=\""
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":23,"column":209},"end":{"line":23,"column":213}} ), depth0))
    + "\">\n            <i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i>\n        </button>\n    </td>\n</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"author"),{"name":"with","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":17,"column":17}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"emoji_author panel_user_list\">"
    + ((stack1 = container.invokePartial(require("../user_display_only_pill.hbs"),depth0,{"name":"../user_display_only_pill","hash":{"img_src":lookupProperty(depth0,"avatar_url"),"display_value":lookupProperty(depth0,"full_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"emoji_author\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Unknown author",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":35},"end":{"line":19,"column":58}}}))
    + "</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"emoji"),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":28,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});