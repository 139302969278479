var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"You are already a member of this group because you are a member of a subgroup",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":89}}}))
    + " (<b>"
    + alias2(container.lambda(container.strict(depth0, "associated_subgroup_names", {"start":{"line":2,"column":96},"end":{"line":2,"column":121}} ), depth0))
    + "</b>).\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Are you sure you want to join it directly as well?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":63}}}))
    + "\n</p>\n";
},"useData":true});