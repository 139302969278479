var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class=\"upload_banner_cancel_button\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":52},"end":{"line":5,"column":67}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"upload_banner file_"
    + alias3(alias2(alias1(depth0, "file_id", {"start":{"line":1,"column":33},"end":{"line":1,"column":40}} ), depth0))
    + " main-view-banner "
    + alias3(alias2(alias1(depth0, "banner_type", {"start":{"line":1,"column":62},"end":{"line":1,"column":73}} ), depth0))
    + "\">\n    <div class=\"moving_bar\"></div>\n    <p class=\"upload_msg banner_content\">"
    + alias3(alias2(alias1(depth0, "banner_text", {"start":{"line":3,"column":43},"end":{"line":3,"column":54}} ), depth0))
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_upload_process_tracker"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "    <a role=\"button\" class=\"zulip-icon zulip-icon-close main-view-banner-close-button\"></a>\n</div>\n";
},"useData":true});