var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"export_row\" id=\"export_"
    + alias3(alias2(alias1(depth0, "id", {"start":{"line":2,"column":36},"end":{"line":2,"column":38}} ), depth0))
    + "\">\n    <td>\n        <span class=\"acting_user\">"
    + alias3(alias2(alias1(depth0, "acting_user", {"start":{"line":4,"column":36},"end":{"line":4,"column":47}} ), depth0))
    + "</span>\n    </td>\n    <td>\n        <span>"
    + alias3(alias2(alias1(depth0, "export_type", {"start":{"line":7,"column":16},"end":{"line":7,"column":27}} ), depth0))
    + "</span>\n    </td>\n    <td>\n        <span class=\"export_time\">"
    + alias3(alias2(alias1(depth0, "event_time", {"start":{"line":10,"column":36},"end":{"line":10,"column":46}} ), depth0))
    + "</span>\n    </td>\n    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"url"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":21,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n    <td class=\"actions\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"url"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":28,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"export_url\"><a href=\""
    + alias1(container.lambda(container.strict(depth0, "url", {"start":{"line":14,"column":44},"end":{"line":14,"column":47}} ), depth0))
    + "\" download>"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Complete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":60},"end":{"line":14,"column":77}}}))
    + "</a></span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"time_failed"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":21,"column":8}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"export_status\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Failed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":36},"end":{"line":16,"column":51}}}))
    + ": "
    + alias1(container.lambda(container.strict(depth0, "time_failed", {"start":{"line":16,"column":55},"end":{"line":16,"column":66}} ), depth0))
    + "</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"pending"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":21,"column":8}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"export_url_spinner\"></div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"time_deleted"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":21,"column":8}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"export_status\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Deleted",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":36},"end":{"line":20,"column":52}}}))
    + ": "
    + alias1(container.lambda(container.strict(depth0, "time_deleted", {"start":{"line":20,"column":56},"end":{"line":20,"column":68}} ), depth0))
    + "</span>\n        ";
},"13":function(container,depth0,helpers,partials,data) {
    return "        <button class=\"button rounded small delete button-danger\" data-export-id=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "id", {"start":{"line":25,"column":84},"end":{"line":25,"column":86}} ), depth0))
    + "\">\n            <i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i>\n        </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"realm_export"),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":31,"column":9}}})) != null ? stack1 : "");
},"useData":true});