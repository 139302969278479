var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none;\"";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = container.invokePartial(require("./notification_settings_checkboxes.hbs"),undefined,{"name":"notification_settings_checkboxes","hash":{"is_mobile_checkbox":lookupProperty(helpers,"eq").call(alias1,depth0,"push_notifications",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":18,"column":29},"end":{"line":18,"column":59}}}),"is_disabled":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(depths[1],"is_disabled"),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":17,"column":22},"end":{"line":17,"column":50}}}),"is_checked":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(depths[1],"stream"),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":16,"column":21},"end":{"line":16,"column":44}}}),"prefix":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(depths[1],"stream"),"stream_id",{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":15,"column":17},"end":{"line":15,"column":47}}}),"setting_name":depth0},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"stream-notifications-row\" data-stream-id=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"stream"), "stream_id", {"start":{"line":1,"column":55},"end":{"line":1,"column":71}} ), depth0))
    + "\">\n    <td>\n        <span class=\"stream-privacy-original-color-"
    + alias3(alias2(alias1(lookupProperty(depth0,"stream"), "stream_id", {"start":{"line":3,"column":53},"end":{"line":3,"column":69}} ), depth0))
    + " stream-privacy filter-icon\" style=\"color: "
    + alias3(alias2(alias1(lookupProperty(depth0,"stream"), "color", {"start":{"line":3,"column":116},"end":{"line":3,"column":128}} ), depth0))
    + "\">\n            "
    + ((stack1 = container.invokePartial(require("../stream_privacy.hbs"),undefined,{"name":"../stream_privacy","hash":{"is_web_public":lookupProperty(lookupProperty(depth0,"stream"),"is_web_public"),"invite_only":lookupProperty(lookupProperty(depth0,"stream"),"invite_only")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </span>\n        "
    + alias3(alias2(alias1(lookupProperty(depth0,"stream"), "stream_name", {"start":{"line":8,"column":10},"end":{"line":8,"column":28}} ), depth0))
    + "\n        <i class=\"zulip-icon zulip-icon-mute unmute_stream\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Unmute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":80},"end":{"line":9,"column":95}}}))
    + "\"\n          "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"muted"),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":10},"end":{"line":10,"column":60}}})) != null ? stack1 : "")
    + "></i>\n    </td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias4,lookupProperty(depth0,"stream_specific_notification_settings"),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":20,"column":13}}})) != null ? stack1 : "")
    + "</tr>\n";
},"usePartial":true,"useData":true,"useDepths":true});