var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias1(container.lambda(container.strict(depth0, "message_id", {"start":{"line":6,"column":50},"end":{"line":6,"column":60}} ), depth0))
    + "\" class=\"respond_button popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-quote-and-reply\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Quote and reply",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":49},"end":{"line":8,"column":73}}}))
    + "</span>\n                "
    + alias1(lookupProperty(helpers,"popover_hotkey_hints").call(alias2,">",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":44}}}))
    + "\n            </a>\n        </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <li role=\"separator\" class=\"popover-menu-separator\"></li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias3(alias2(alias1(depth0, "message_id", {"start":{"line":19,"column":50},"end":{"line":19,"column":60}} ), depth0))
    + "\" class=\"popover_edit_message popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-edit\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias3(alias2(alias1(depth0, "editability_menu_item", {"start":{"line":21,"column":51},"end":{"line":21,"column":72}} ), depth0))
    + "</span>\n                "
    + alias3(lookupProperty(helpers,"popover_hotkey_hints").call(depth0 != null ? depth0 : (container.nullContext || {}),"E",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":22,"column":44}}}))
    + "\n            </a>\n        </li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias3(alias2(alias1(depth0, "message_id", {"start":{"line":28,"column":50},"end":{"line":28,"column":60}} ), depth0))
    + "\" class=\"popover_move_message popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-move-alt\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias3(alias2(alias1(depth0, "move_message_menu_item", {"start":{"line":30,"column":51},"end":{"line":30,"column":73}} ), depth0))
    + "</span>\n                "
    + alias3(lookupProperty(helpers,"popover_hotkey_hints").call(depth0 != null ? depth0 : (container.nullContext || {}),"M",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":31,"column":16},"end":{"line":31,"column":44}}}))
    + "\n            </a>\n        </li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias1(container.lambda(container.strict(depth0, "message_id", {"start":{"line":37,"column":50},"end":{"line":37,"column":60}} ), depth0))
    + "\" class=\"delete_message popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-trash\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Delete message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":49},"end":{"line":39,"column":72}}}))
    + "</span>\n            </a>\n        </li>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"separator\" class=\"popover-menu-separator\"></li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias1(container.lambda(container.strict(depth0, "message_id", {"start":{"line":47,"column":50},"end":{"line":47,"column":60}} ), depth0))
    + "\" class=\"reaction_button popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-smile\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Add emoji reaction",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":49},"end":{"line":49,"column":76}}}))
    + "</span>\n                "
    + alias1(lookupProperty(helpers,"popover_hotkey_hints").call(alias2,":",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":50,"column":16},"end":{"line":50,"column":44}}}))
    + "\n            </a>\n        </li>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias1(container.lambda(container.strict(depth0, "message_id", {"start":{"line":60,"column":50},"end":{"line":60,"column":60}} ), depth0))
    + "\" class=\"mark_as_unread popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-mark-as-unread\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Mark as unread from here",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":49},"end":{"line":62,"column":82}}}))
    + "</span>\n                "
    + alias1(lookupProperty(helpers,"popover_hotkey_hints").call(alias2,"Shift","U",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":63,"column":16},"end":{"line":63,"column":52}}}))
    + "\n            </a>\n        </li>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias1(container.lambda(container.strict(depth0, "message_id", {"start":{"line":69,"column":50},"end":{"line":69,"column":60}} ), depth0))
    + "\" class=\"rehide_muted_user_message popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-hide\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Hide muted message again",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":49},"end":{"line":71,"column":82}}}))
    + "</span>\n            </a>\n        </li>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias1(container.lambda(container.strict(depth0, "message_id", {"start":{"line":77,"column":50},"end":{"line":77,"column":60}} ), depth0))
    + "\" class=\"popover_toggle_collapse popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-collapse\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Collapse message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":79,"column":49},"end":{"line":79,"column":74}}}))
    + "</span>\n                "
    + alias1(lookupProperty(helpers,"popover_hotkey_hints").call(alias2,"-",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":80,"column":16},"end":{"line":80,"column":44}}}))
    + "\n            </a>\n        </li>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias1(container.lambda(container.strict(depth0, "message_id", {"start":{"line":86,"column":50},"end":{"line":86,"column":60}} ), depth0))
    + "\" class=\"popover_toggle_collapse popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-expand\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Expand message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":88,"column":49},"end":{"line":88,"column":72}}}))
    + "</span>\n                "
    + alias1(lookupProperty(helpers,"popover_hotkey_hints").call(alias2,"-",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":89,"column":16},"end":{"line":89,"column":44}}}))
    + "\n            </a>\n        </li>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias3(alias2(alias1(depth0, "message_id", {"start":{"line":97,"column":50},"end":{"line":97,"column":60}} ), depth0))
    + "\" class=\"popover_view_source popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-source\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias3(alias2(alias1(depth0, "view_source_menu_item", {"start":{"line":99,"column":51},"end":{"line":99,"column":72}} ), depth0))
    + "</span>\n                "
    + alias3(lookupProperty(helpers,"popover_hotkey_hints").call(depth0 != null ? depth0 : (container.nullContext || {}),"E",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":100,"column":16},"end":{"line":100,"column":44}}}))
    + "\n            </a>\n        </li>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" data-message-id=\""
    + alias1(container.lambda(container.strict(depth0, "message_id", {"start":{"line":106,"column":50},"end":{"line":106,"column":60}} ), depth0))
    + "\" class=\"view_read_receipts popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-readreceipts\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"View read receipts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":108,"column":49},"end":{"line":108,"column":76}}}))
    + "</span>\n                "
    + alias1(lookupProperty(helpers,"popover_hotkey_hints").call(alias2,"Shift","V",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":109,"column":16},"end":{"line":109,"column":52}}}))
    + "\n            </a>\n        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu\" id=\"message-actions-menu-dropdown\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <ul role=\"menu\" class=\"popover-menu-list\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_quote_and_reply"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":12,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"editability_menu_item"),lookupProperty(depth0,"move_message_menu_item"),lookupProperty(depth0,"should_display_delete_option"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":14,"column":14},"end":{"line":14,"column":92}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"editability_menu_item"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":25,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"move_message_menu_item"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":34,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_delete_option"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":8},"end":{"line":42,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_add_reaction_option"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":8},"end":{"line":53,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"should_display_mark_as_unread"),lookupProperty(depth0,"should_display_hide_option"),lookupProperty(depth0,"should_display_collapse"),lookupProperty(depth0,"should_display_uncollapse"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":55,"column":14},"end":{"line":55,"column":125}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":8},"end":{"line":57,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_mark_as_unread"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":8},"end":{"line":66,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_hide_option"),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":8},"end":{"line":74,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_collapse"),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":8},"end":{"line":83,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_uncollapse"),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":84,"column":8},"end":{"line":92,"column":15}}})) != null ? stack1 : "")
    + "        <li role=\"separator\" class=\"popover-menu-separator hidden-for-spectators\"></li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"view_source_menu_item"),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":95,"column":8},"end":{"line":103,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_read_receipts_option"),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":104,"column":8},"end":{"line":112,"column":15}}})) != null ? stack1 : "")
    + "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"copy_link navigate-link-on-enter popover-menu-link\" data-message-id=\""
    + alias4(alias3(alias2(depth0, "message_id", {"start":{"line":114,"column":109},"end":{"line":114,"column":119}} ), depth0))
    + "\" data-clipboard-text=\""
    + alias4(alias3(alias2(depth0, "conversation_time_url", {"start":{"line":114,"column":147},"end":{"line":114,"column":168}} ), depth0))
    + "\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-link-alt\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"Copy link to message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":116,"column":49},"end":{"line":116,"column":78}}}))
    + "</span>\n            </a>\n        </li>\n    </ul>\n</div>\n";
},"useData":true});