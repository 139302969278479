var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class=\"button rounded sea-green\" id=\"add-custom-profile-field-button\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Add a new profile field",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":86},"end":{"line":6,"column":118}}}))
    + "</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <th class=\"display\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Card",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":40},"end":{"line":17,"column":52}}}))
    + "</th>\n                    <th class=\"required\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Required",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":41},"end":{"line":18,"column":57}}}))
    + "</th>\n                    <th class=\"actions\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":40},"end":{"line":19,"column":56}}}))
    + "</th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"profile-field-settings\" class=\"settings-section\" data-name=\"profile-field-settings\">\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Custom profile fields",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":41}}}))
    + "</h3>\n        <div class=\"alert-notification\" id=\"admin-profile-field-status\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"progressive-table-wrapper\" data-simplebar data-simplebar-tab-index=\"-1\">\n        <table class=\"table table-striped admin_profile_fields_table\">\n            <thead>\n                <tr>\n                    <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Label",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":24},"end":{"line":13,"column":38}}}))
    + "</th>\n                    <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Hint",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":14,"column":37}}}))
    + "</th>\n                    <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Type",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":15,"column":37}}}))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":20,"column":27}}})) != null ? stack1 : "")
    + "                </tr>\n            </thead>\n            <tbody id=\"admin_profile_fields_table\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No custom profile fields configured.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":63},"end":{"line":23,"column":108}}}))
    + "\"></tbody>\n        </table>\n    </div>\n</div>\n";
},"useData":true});