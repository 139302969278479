var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"input-group\">\n    <div class=\"integration-url-name-wrapper integration-url-parameter\">\n        "
    + ((stack1 = container.invokePartial(require("../dropdown_widget_with_label.hbs"),undefined,{"name":"../dropdown_widget_with_label","hash":{"label":lookupProperty(helpers,"t").call(alias1,"Integration",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":33}}}),"widget_name":"integration-name"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n</div>\n<div class=\"input-group\">\n    <div class=\"integration-url-stream-wrapper integration-url-parameter\">\n        "
    + ((stack1 = container.invokePartial(require("../dropdown_widget_with_label.hbs"),undefined,{"name":"../dropdown_widget_with_label","hash":{"label":lookupProperty(helpers,"t").call(alias1,"Where to send notifications",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":49}}}),"widget_name":"integration-url-stream"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n</div>\n<div class=\"input-group control-label-disabled\">\n    <label class=\"checkbox\">\n        <input type=\"checkbox\" id=\"integration-url-override-topic\" class=\"integration-url-parameter\" disabled/>\n        <span class=\"rendered-checkbox\"></span>\n    </label>\n    <label class=\"inline\" for=\"integration-url-override-topic\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Send all notifications to a single topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":21,"column":56}}}))
    + "\n    </label>\n</div>\n<div class=\"input-group hide\">\n    <label for=\"integration-url-topic-input\" class=\"modal-label-field\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":71},"end":{"line":25,"column":84}}}))
    + "</label>\n    <input type=\"text\" id=\"integration-url-topic-input\" class=\"modal_text_input integration-url-parameter\" maxlength=\""
    + alias2(alias4(alias3(depth0, "max_topic_length", {"start":{"line":26,"column":121},"end":{"line":26,"column":137}} ), depth0))
    + "\" />\n</div>\n<div id=\"integration-url-config-options-container\">\n    <!-- Dynamic Config Options will be rendered here -->\n</div>\n<div id=\"integration-events-parameter\" class=\"input-group hide\">\n    <label class=\"checkbox\">\n        <input type=\"checkbox\" id=\"show-integration-events\"/>\n        <span class=\"rendered-checkbox\"></span>\n    </label>\n    <label class=\"inline\" for=\"show-integration-events\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter events that will trigger notifications?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":8},"end":{"line":37,"column":62}}}))
    + "\n    </label>\n</div>\n<div class=\"input-group hide\" id=\"integrations-event-container\">\n    <label for=\"integrations-event-options\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Events to include:",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":44},"end":{"line":41,"column":70}}}))
    + "</label>\n    <div class=\"integration-all-events-buttons\">\n        <button class=\"button rounded\" id=\"add-all-integration-events\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Check all",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":71},"end":{"line":43,"column":88}}}))
    + "</button>\n        <button class=\"button rounded\" id=\"remove-all-integration-events\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Uncheck all",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":74},"end":{"line":44,"column":93}}}))
    + "</button>\n    </div>\n    <div id=\"integrations-event-options\"></div>\n</div>\n<hr />\n<p class=\"integration-url-header\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"URL for your integration",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":34},"end":{"line":49,"column":66}}}))
    + "</p>\n<div class=\"integration-url\">\n    "
    + alias2(alias4(alias3(depth0, "default_url_message", {"start":{"line":51,"column":6},"end":{"line":51,"column":25}} ), depth0))
    + "\n</div>\n";
},"usePartial":true,"useData":true});