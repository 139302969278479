var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "Zulip needs your permission to <z-link>enable desktop notifications.</z-link>\n        \n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a class=\"request-desktop-notifications alert-link\" role=\"button\" tabindex=0>"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),undefined,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-step=\"1\">\n    "
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":11}}})) != null ? stack1 : "")
    + "</div>\n<div data-step=\"2\" style=\"display: none\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"We strongly recommend enabling desktop notifications. They help Zulip keep your team connected.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":7,"column":108}}}))
    + "\n    <span class=\"buttons\">\n        <a class=\"alert-link request-desktop-notifications\" role=\"button\" tabindex=0>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Enable notifications",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":85},"end":{"line":9,"column":114}}}))
    + "</a>\n        &bull;\n        <a class=\"alert-link exit\" role=\"button\" tabindex=0>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Ask me later",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":60},"end":{"line":11,"column":81}}}))
    + "</a>\n        &bull;\n        <a class=\"alert-link reject-notifications\" role=\"button\" tabindex=0>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Never ask on this computer",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":76},"end":{"line":13,"column":111}}}))
    + "</a>\n    </span>\n</div>\n";
},"1_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":141}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});