var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"remove\">\n        <div class=\"subgroup_list_remove\">\n            <form class=\"remove-subgroup-form\">\n                <button type=\"submit\" name=\"remove\" class=\"remove-subgroup-button button small rounded button-danger\">\n                    "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Remove",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":34}}}))
    + "\n                </button>\n            </form>\n        </div>\n    </td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr data-subgroup-id=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "group_id", {"start":{"line":1,"column":24},"end":{"line":1,"column":32}} ), depth0))
    + "\">\n    <td class=\"subgroup-name panel_user_list\" colspan=\"2\">\n        "
    + ((stack1 = container.invokePartial(require("../user_group_display_only_pill.hbs"),depth0,{"name":"../user_group_display_only_pill","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"can_remove_members"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":15,"column":11}}})) != null ? stack1 : "")
    + "</tr>\n";
},"usePartial":true,"useData":true});