var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <button class=\"poll-vote "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"current_user_vote"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":33},"end":{"line":3,"column":82}}})) != null ? stack1 : "")
    + "\" data-key=\""
    + alias4(alias3(alias2(depth0, "key", {"start":{"line":3,"column":97},"end":{"line":3,"column":100}} ), depth0))
    + "\">\n            "
    + alias4(alias3(alias2(depth0, "count", {"start":{"line":4,"column":15},"end":{"line":4,"column":20}} ), depth0))
    + "\n        </button>\n        <span class=\"poll-option\">"
    + alias4(alias3(alias2(depth0, "option", {"start":{"line":6,"column":37},"end":{"line":6,"column":43}} ), depth0))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"names"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "    </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "current-user-vote";
},"4":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"poll-names\">("
    + container.escapeExpression(container.lambda(container.strict(depth0, "names", {"start":{"line":8,"column":37},"end":{"line":8,"column":42}} ), depth0))
    + ")</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"options"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":11,"column":9}}})) != null ? stack1 : "");
},"useData":true});