var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"micromodal\" id=\"profile_access_error_modal\" aria-hidden=\"true\">\n    <div class=\"modal__overlay\" tabindex=\"-1\">\n        <div class=\"modal__container\" role=\"dialog\" aria-modal=\"true\" aria-labelledby=\"profile_access_error_modal_label\">\n            <header class=\"modal__header\">\n                <h1 class=\"modal__title\" id=\"profile_access_error_modal_label\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"No user found",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":42}}}))
    + "\n                </h1>\n                <button class=\"modal__close\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Close modal",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":57},"end":{"line":8,"column":77}}}))
    + "\" data-micromodal-close></button>\n            </header>\n            <main class=\"modal__content\">\n                <p>\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Either this user does not exist, or you do not have access to their profile.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":12,"column":105}}}))
    + "\n                </p>\n            </main>\n            <footer class=\"modal__footer\">\n                <button type=\"button\" class=\"modal__button dialog_exit_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Close this dialog window",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":91},"end":{"line":16,"column":124}}}))
    + "\" data-micromodal-close>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Close",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":148},"end":{"line":16,"column":162}}}))
    + "</button>\n            </footer>\n        </div>\n    </div>\n</div>\n";
},"useData":true});