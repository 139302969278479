var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            {subscribers_count, plural, =0 {No subscribers} =1 {1 subscriber} other {# subscribers}}\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu\" id=\"stream-card-popover\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <ul role=\"menu\" class=\"popover-menu-list\">\n        <li role=\"none\" class=\"popover-stream-header text-item popover-menu-list-item\">\n            <span class=\"stream-privacy-original-color-"
    + alias3(alias2(alias1(lookupProperty(depth0,"stream"), "stream_id", {"start":{"line":4,"column":57},"end":{"line":4,"column":73}} ), depth0))
    + " stream-privacy filter-icon\" style=\"color: "
    + alias3(alias2(alias1(lookupProperty(depth0,"stream"), "color", {"start":{"line":4,"column":120},"end":{"line":4,"column":132}} ), depth0))
    + "\">\n                "
    + ((stack1 = container.invokePartial(require("../stream_privacy.hbs"),undefined,{"name":"../stream_privacy","hash":{"is_web_public":lookupProperty(lookupProperty(depth0,"stream"),"is_web_public"),"invite_only":lookupProperty(lookupProperty(depth0,"stream"),"invite_only")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </span>\n            <span class=\"popover-stream-name\">"
    + alias3(alias2(alias1(lookupProperty(depth0,"stream"), "name", {"start":{"line":9,"column":48},"end":{"line":9,"column":59}} ), depth0))
    + "</span>\n        </li>\n        <li role=\"none\" class=\"popover-stream-info-menu-description text-item popover-menu-list-item\">\n            "
    + ((stack1 = container.invokePartial(require("../stream_settings/stream_description.hbs"),undefined,{"name":"../stream_settings/stream_description","hash":{"rendered_description":lookupProperty(lookupProperty(depth0,"stream"),"rendered_description")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </li>\n        <li role=\"none\" class=\"popover-menu-list-item text-item italic\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias4,{"name":"tr","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + "        </li>\n        <li role=\"separator\" class=\"popover-menu-separator hidden-for-spectators\"></li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n            <a role=\"menuitem\" class=\"open_stream_settings popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-gear\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Channel settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":49},"end":{"line":24,"column":74}}}))
    + "</span>\n            </a>\n        </li>\n    </ul>\n</div>\n";
},"usePartial":true,"useData":true});