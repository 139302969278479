var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "hidden_by_filters";
},"3":function(container,depth0,helpers,partials,data) {
    return "icon-collapsed-state";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"inbox-header-stream-archived\">\n                                <i class=\"archived-indicator\">("
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"archived",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":63},"end":{"line":14,"column":80}}}))
    + ")</i>\n                            </span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"inbox-stream-header-"
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":1,"column":31},"end":{"line":1,"column":40}} ), depth0))
    + "\" class=\"inbox-header "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_hidden"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":64},"end":{"line":1,"column":105}}})) != null ? stack1 : "")
    + "\" tabindex=\"0\" data-stream-id=\""
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":1,"column":138},"end":{"line":1,"column":147}} ), depth0))
    + "\" style=\"background: "
    + alias3(alias2(alias1(depth0, "stream_header_color", {"start":{"line":1,"column":172},"end":{"line":1,"column":191}} ), depth0))
    + ";\">\n    <div class=\"inbox-focus-border\">\n        <div class=\"inbox-left-part-wrapper\">\n            <div class=\"collapsible-button\"><i class=\"zulip-icon zulip-icon-arrow-down toggle-inbox-header-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_collapsed"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":112},"end":{"line":4,"column":159}}})) != null ? stack1 : "")
    + "\"></i></div>\n            <div class=\"inbox-left-part\">\n                <div tabindex=\"0\" class=\"inbox-header-name\">\n                    <div class=\"inbox-header-name-focus-border\">\n                        <span class=\"stream-privacy-original-color-"
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":8,"column":69},"end":{"line":8,"column":78}} ), depth0))
    + " stream-privacy filter-icon\" style=\"color: "
    + alias3(alias2(alias1(depth0, "stream_color", {"start":{"line":8,"column":125},"end":{"line":8,"column":137}} ), depth0))
    + "\">\n                            "
    + ((stack1 = container.invokePartial(require("../stream_privacy.hbs"),depth0,{"name":"../stream_privacy","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </span>\n                        <a tabindex=\"-1\" href=\""
    + alias3(alias2(alias1(depth0, "stream_url", {"start":{"line":11,"column":49},"end":{"line":11,"column":59}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "stream_name", {"start":{"line":11,"column":65},"end":{"line":11,"column":76}} ), depth0))
    + "</a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_archived"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":24},"end":{"line":16,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n                <span class=\"unread_mention_info tippy-zulip-tooltip\n                  "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"mention_in_unread"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":18},"end":{"line":20,"column":64}}})) != null ? stack1 : "")
    + "\"\n                  data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"You have mentions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":38},"end":{"line":21,"column":63}}}))
    + "\">@</span>\n                <div class=\"unread-count-focus-outline\" tabindex=\"0\">\n                    <span class=\"unread_count tippy-zulip-tooltip on_hover_topic_read\"\n                      data-stream-id=\""
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":24,"column":40},"end":{"line":24,"column":49}} ), depth0))
    + "\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Mark as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":73},"end":{"line":24,"column":94}}}))
    + "\"\n                      aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Mark as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":34},"end":{"line":25,"column":55}}}))
    + "\">"
    + alias3(alias2(alias1(depth0, "unread_count", {"start":{"line":25,"column":59},"end":{"line":25,"column":71}} ), depth0))
    + "</span>\n                </div>\n                <div class=\"topic-visibility-indicator invisible\" tabindex=\"0\"></div>\n            </div>\n        </div>\n        <div class=\"inbox-right-part-wrapper\">\n            <div class=\"inbox-right-part\">\n                <div class=\"inbox-action-button inbox-stream-menu\" data-stream-id=\""
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":32,"column":85},"end":{"line":32,"column":94}} ), depth0))
    + "\" tabindex=\"0\">\n                    <i class=\"zulip-icon zulip-icon-more-vertical\" aria-hidden=\"true\"></i>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});