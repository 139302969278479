var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr data-user-id=\""
    + alias3(alias2(alias1(depth0, "user_id", {"start":{"line":2,"column":20},"end":{"line":2,"column":27}} ), depth0))
    + "\" data-user-name=\""
    + alias3(alias2(alias1(depth0, "user_name", {"start":{"line":2,"column":49},"end":{"line":2,"column":58}} ), depth0))
    + "\" data-date-muted=\""
    + alias3(alias2(alias1(depth0, "date_muted_str", {"start":{"line":2,"column":81},"end":{"line":2,"column":95}} ), depth0))
    + "\">\n    <td>"
    + alias3(alias2(alias1(depth0, "user_name", {"start":{"line":3,"column":10},"end":{"line":3,"column":19}} ), depth0))
    + "</td>\n    <td>"
    + alias3(alias2(alias1(depth0, "date_muted_str", {"start":{"line":4,"column":10},"end":{"line":4,"column":24}} ), depth0))
    + "</td>\n    <td class=\"actions\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"can_unmute"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span><a class=\"settings-unmute-user\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Unmute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":46},"end":{"line":7,"column":61}}}))
    + "</a></span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"muted_user"),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":11,"column":9}}})) != null ? stack1 : "");
},"useData":true});