var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"member_list_add float-left\">\n    "
    + ((stack1 = container.invokePartial(require("./add_members_form.hbs"),undefined,{"name":"add_members_form","hash":{"hide_add_button":true},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n<br />\n\n"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Do you want to add everyone?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":6,"column":36}}}))
    + "\n<button class=\"add_all_users_to_user_group small button rounded sea-green\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add all users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":75},"end":{"line":7,"column":96}}}))
    + "</button>\n\n<div class=\"create_member_list_header\">\n    <h4 class=\"user_group_setting_subsection_title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Members preview",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":52},"end":{"line":10,"column":76}}}))
    + "</h4>\n    <input class=\"add-user-list-filter filter_text_input\" name=\"user_list_filter\" type=\"text\"\n      autocomplete=\"off\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter members",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":38},"end":{"line":12,"column":61}}}))
    + "\" />\n</div>\n\n<div class=\"member-list-box\">\n    <div class=\"member_list_container\" data-simplebar data-simplebar-tab-index=\"-1\">\n        <table class=\"member-list table table-striped\">\n            <thead class=\"table-sticky-headers\">\n                <th data-sort=\"alphabetic\" data-sort-prop=\"full_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":70},"end":{"line":19,"column":83}}}))
    + "</th>\n                <th class=\"settings-email-column\" data-sort=\"email\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":68},"end":{"line":20,"column":82}}}))
    + "</th>\n                <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Action",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":21,"column":35}}}))
    + "</th>\n            </thead>\n            <tbody id=\"create_user_group_members\" class=\"member_table\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"This group has no members.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":83},"end":{"line":23,"column":118}}}))
    + "\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No group members match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":147},"end":{"line":23,"column":198}}}))
    + "\"></tbody>\n        </table>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});