var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"link-item popover-menu-list-item\">\n                <a href=\""
    + alias1(container.lambda(container.strict(depth0, "playground_url", {"start":{"line":5,"column":27},"end":{"line":5,"column":46}} ), depth0))
    + "\" target=\"_blank\" rel=\"noopener noreferrer\" role=\"menuitem\" class=\"popover_playground_link popover-menu-link\" tabindex=\"0\">\n                    <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"View in {name}",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":53},"end":{"line":6,"column":76}}}))
    + "</span>\n                </a>\n            </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu playground-links-popover\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <ul role=\"menu\" class=\"popover-menu-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"playground_info"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":9,"column":17}}})) != null ? stack1 : "")
    + "    </ul>\n</div>\n";
},"useData":true});