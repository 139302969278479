var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"add-alert-word-form\">\n    <label for=\"add-alert-word-name\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Alert word",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":63},"end":{"line":2,"column":82}}}))
    + "</label>\n    <input type=\"text\" name=\"alert-word-name\" id=\"add-alert-word-name\" class=\"required modal_text_input\" maxlength=100 placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Alert word",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":132},"end":{"line":3,"column":151}}}))
    + "\" value=\"\" />\n</form>\n";
},"useData":true});