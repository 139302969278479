var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"table table-stripped\" id=\"realm_domains_table\">\n    <thead>\n        <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Domain",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":27}}}))
    + "</th>\n        <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Allow subdomains",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":36}}}))
    + "</th>\n        <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Action",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":27}}}))
    + "</th>\n    </thead>\n    <tbody>\n    </tbody>\n    <tfoot>\n        <tr id=\"add-realm-domain-widget\">\n            <td><input type=\"text\" class=\"new-realm-domain modal_text_input\" placeholder=\"acme.com\" /></td>\n            <td>\n                <label class=\"checkbox\">\n                    <input type=\"checkbox\" class=\"new-realm-domain-allow-subdomains\" />\n                    <span class=\"rendered-checkbox\"></span>\n                </label>\n            </td>\n            <td><button type=\"button\" class=\"button sea-green small rounded\" id=\"submit-add-realm-domain\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":106},"end":{"line":18,"column":118}}}))
    + "</button></td>\n        </tr>\n    </tfoot>\n</table>\n<div class=\"alert realm_domains_info\"></div>\n";
},"useData":true});