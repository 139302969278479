var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "<tr>\n    <td class=\"empty-table-message\" colspan=\""
    + alias3(alias2(alias1(depth0, "column_count", {"start":{"line":2,"column":47},"end":{"line":2,"column":59}} ), depth0))
    + "\">\n        "
    + alias3(alias2(alias1(depth0, "empty_list_message", {"start":{"line":3,"column":10},"end":{"line":3,"column":28}} ), depth0))
    + "\n    </td>\n</tr>\n";
},"useData":true});