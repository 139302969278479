var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=container.strict, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"You do not have permission to move some of the messages in this topic. Contact a moderator to move all messages.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":3},"end":{"line":1,"column":123}}}))
    + "\n</p>\n<p>\n    "
    + alias1(alias3(alias2(depth0, "messages_allowed_to_move_text", {"start":{"line":4,"column":6},"end":{"line":4,"column":35}} ), depth0))
    + "\n    "
    + alias1(alias3(alias2(depth0, "messages_not_allowed_to_move_text", {"start":{"line":5,"column":6},"end":{"line":5,"column":39}} ), depth0))
    + "\n</p>\n";
},"useData":true});