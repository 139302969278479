var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"change_password_container\">\n    <div class=\"settings-password-div\">\n        <label for=\"old_password\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Old password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":60},"end":{"line":3,"column":81}}}))
    + "</label>\n        <div class=\"password-input-row\">\n            <input type=\"password\" autocomplete=\"off\" name=\"old_password\" id=\"old_password\" class=\"inline-block modal_password_input\" value=\"\" />\n            <i class=\"fa fa-eye-slash password_visibility_toggle tippy-zulip-tooltip\" role=\"button\" tabindex=\"0\"></i>\n            <a href=\"/accounts/password/reset/\" class=\"settings-forgot-password sea-green\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Forgot it?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":133},"end":{"line":7,"column":152}}}))
    + "</a>\n        </div>\n    </div>\n    <div class=\"settings-password-div\">\n        <label for=\"new_password\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"New password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":60},"end":{"line":11,"column":81}}}))
    + "</label>\n        <div class=\"password-input-row\">\n            <input type=\"password\" autocomplete=\"new-password\" name=\"new_password\" id=\"new_password\" class=\"inline-block modal_password_input\" value=\"\"\n              data-min-length=\""
    + alias2(alias4(alias3(depth0, "password_min_length", {"start":{"line":14,"column":33},"end":{"line":14,"column":52}} ), depth0))
    + "\" data-min-guesses=\""
    + alias2(alias4(alias3(depth0, "password_min_guesses", {"start":{"line":14,"column":76},"end":{"line":14,"column":96}} ), depth0))
    + "\" />\n            <i class=\"fa fa-eye-slash password_visibility_toggle tippy-zulip-tooltip\" role=\"button\" tabindex=\"0\"></i>\n        </div>\n        <div class=\"progress inline-block\" id=\"pw_strength\">\n            <div class=\"bar bar-danger hide\" style=\"width: 10%;\"></div>\n        </div>\n    </div>\n</form>\n";
},"useData":true});