var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"alert-word-settings\" class=\"settings-section\" data-name=\"alert-words\">\n    <form id=\"alert_word_info_box\">\n        <p class=\"alert-word-settings-note\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Alert words allow you to be notified as if you were @-mentioned when certain words or phrases are used in Zulip. Alert words are not case sensitive.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":168}}}))
    + "\n        </p>\n    </form>\n    <button class=\"button rounded sea-green\" id=\"open-add-alert-word-modal\" type=\"button\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add alert word",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":30}}}))
    + "\n    </button>\n\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Alert words",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":31}}}))
    + "</h3>\n    </div>\n    <div class=\"alert\" id=\"alert_word_status\" role=\"alert\">\n        <button type=\"button\" class=\"close close-alert-word-status\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Close",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":80},"end":{"line":15,"column":94}}}))
    + "\">\n            <span aria-hidden=\"true\">&times;</span>\n        </button>\n        <span class=\"alert_word_status_text\"></span>\n    </div>\n    <div class=\"progressive-table-wrapper\" data-simplebar data-simplebar-tab-index=\"-1\">\n        <table class=\"table table-striped wrapped-table\">\n            <thead class=\"table-sticky-headers\">\n                <th data-sort=\"alphabetic\" data-sort-prop=\"word\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Word",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":65},"end":{"line":23,"column":78}}}))
    + "</th>\n                <th class=\"actions\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":36},"end":{"line":24,"column":52}}}))
    + "</th>\n            </thead>\n            <tbody id=\"alert-words-table\" class=\"alert-words-table\"\n              data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"There are no current alert words.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":26},"end":{"line":27,"column":68}}}))
    + "\"></tbody>\n        </table>\n    </div>\n</div>\n";
},"useData":true});