var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" id=\"mark_all_messages_as_read\" class=\"popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-mark-as-read\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mark all messages as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":49},"end":{"line":7,"column":83}}}))
    + "</span>\n            </a>\n        </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"set-home-view popover-menu-link\" data-view-code=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "view_code", {"start":{"line":12,"column":89},"end":{"line":12,"column":98}} ), depth0))
    + "\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-house\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":17,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n            </a>\n        </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                        Make <b>inbox</b> my home view\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <ul role=\"menu\" class=\"popover-menu-list\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_home_view"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":21,"column":15}}})) != null ? stack1 : "")
    + "    </ul>\n</div>\n";
},"useData":true});