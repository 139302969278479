var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"sidebar-item\" tabindex=\"0\" data-section=\"your-bots\">\n                        <i class=\"sidebar-item-icon zulip-icon zulip-icon-bot\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Bots",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":42},"end":{"line":34,"column":55}}}))
    + "</div>\n                    </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"sidebar-item\" tabindex=\"0\" data-section=\"uploaded-files\">\n                        <i class=\"sidebar-item-icon fa fa-paperclip\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Uploaded files",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":42},"end":{"line":44,"column":65}}}))
    + "</div>\n                    </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none;\"";
},"7":function(container,depth0,helpers,partials,data) {
    return "hide-org-settings";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"sidebar-item\" tabindex=\"0\" data-section=\"users\">\n                        <i class=\"sidebar-item-icon fa fa-user\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":91,"column":42},"end":{"line":91,"column":56}}}))
    + "</div>\n                        <i class=\"locked fa fa-lock tippy-zulip-tooltip\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_edit_user_panel"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":92,"column":73},"end":{"line":92,"column":130}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Only organization administrators can edit these settings.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":92,"column":151},"end":{"line":92,"column":217}}}))
    + "\"></i>\n                    </li>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"sidebar-item\" tabindex=\"0\" data-section=\"bot-list-admin\">\n                        <i class=\"sidebar-item-icon zulip-icon zulip-icon-bot\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Bots",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":98,"column":42},"end":{"line":98,"column":55}}}))
    + "</div>\n                        <i class=\"locked fa fa-lock tippy-zulip-tooltip\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_create_new_bots"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":73},"end":{"line":99,"column":129}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Only organization administrators can edit these settings.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":99,"column":150},"end":{"line":99,"column":216}}}))
    + "\"></i>\n                    </li>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"sidebar-item\" tabindex=\"0\" data-section=\"profile-field-settings\">\n                        <i class=\"sidebar-item-icon fa fa-id-card\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Custom profile fields",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":105,"column":42},"end":{"line":105,"column":72}}}))
    + "</div>\n                    </li>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"sidebar-item collapse-org-settings "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":66},"end":{"line":114,"column":114}}})) != null ? stack1 : "")
    + "\" tabindex=\"0\" data-section=\"default-channels-list\">\n                        <i class=\"sidebar-item-icon fa fa-exchange\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Default channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":116,"column":42},"end":{"line":116,"column":67}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":117,"column":24},"end":{"line":119,"column":35}}})) != null ? stack1 : "")
    + "                    </li>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <i class=\"locked fa fa-lock tippy-zulip-tooltip\" data-tippy-content=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Only organization administrators can edit these settings.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":118,"column":93},"end":{"line":118,"column":159}}}))
    + "\"></i>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"sidebar-item\" tabindex=\"0\" data-section=\"data-exports-admin\">\n                        <i class=\"sidebar-item-icon fa fa-database\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Data exports",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":130,"column":42},"end":{"line":130,"column":63}}}))
    + "</div>\n                    </li>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"sidebar-item collapse-settings-button\">\n                        <i id='toggle_collapse_chevron' class='sidebar-item-icon fa fa-angle-double-down'></i>\n                        <div class=\"text\" id='toggle_collapse'>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Show more",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":136,"column":63},"end":{"line":136,"column":81}}}))
    + "</div>\n                    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"settings_page\" class=\"overlay-content overlay-container\">\n    <div class=\"settings-header mobile\">\n        <i class=\"fa fa-chevron-left\" aria-hidden=\"true\"></i>\n        <h1>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":29}}}))
    + "<span class=\"section\"></span></h1>\n        <div class=\"exit\">\n            <span class=\"exit-sign\">&times;</span>\n        </div>\n        <div class=\"clear-float\"></div>\n    </div>\n    <div class=\"sidebar-wrapper\">\n        <div class=\"tab-container\"></div>\n        <div class=\"sidebar left\" data-simplebar data-simplebar-tab-index=\"-1\">\n            <div class=\"sidebar-list dark-grey small-text\">\n                <ul class=\"normal-settings-list\">\n                    <li class=\"sidebar-item\" tabindex=\"0\" data-section=\"profile\">\n                        <i class=\"sidebar-item-icon fa fa-user\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":42},"end":{"line":17,"column":58}}}))
    + "</div>\n                    </li>\n                    <li class=\"sidebar-item\" tabindex=\"0\" data-section=\"account-and-privacy\">\n                        <i class=\"sidebar-item-icon fa fa-lock\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Account & privacy",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":42},"end":{"line":21,"column":68}}}))
    + "</div>\n                    </li>\n                    <li class=\"sidebar-item\" tabindex=\"0\" data-section=\"preferences\">\n                        <i class=\"sidebar-item-icon fa fa-sliders\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Preferences",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":42},"end":{"line":25,"column":62}}}))
    + "</div>\n                    </li>\n                    <li class=\"sidebar-item\" tabindex=\"0\" data-section=\"notifications\">\n                        <i class=\"sidebar-item-icon fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Notifications",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":42},"end":{"line":29,"column":64}}}))
    + "</div>\n                    </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_guest"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":20},"end":{"line":36,"column":31}}})) != null ? stack1 : "")
    + "                    <li class=\"sidebar-item\" tabindex=\"0\" data-section=\"alert-words\">\n                        <i class=\"sidebar-item-icon fa fa-book\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Alert words",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":42},"end":{"line":39,"column":62}}}))
    + "</div>\n                    </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"show_uploaded_files_section"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":20},"end":{"line":46,"column":27}}})) != null ? stack1 : "")
    + "                    <li class=\"sidebar-item\" tabindex=\"0\" data-section=\"topics\">\n                        <i class=\"sidebar-item-icon zulip-icon zulip-icon-topic\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Topics",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":42},"end":{"line":49,"column":57}}}))
    + "</div>\n                    </li>\n                    <li class=\"sidebar-item\" tabindex=\"0\" data-section=\"muted-users\">\n                        <i class=\"sidebar-item-icon fa fa-eye-slash\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Muted users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":42},"end":{"line":53,"column":62}}}))
    + "</div>\n                    </li>\n                </ul>\n\n                <ul class=\"org-settings-list\">\n                    <li class=\"sidebar-item\" tabindex=\"0\" data-section=\"organization-profile\">\n                        <i class=\"sidebar-item-icon fa fa-id-card\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":42},"end":{"line":60,"column":71}}}))
    + "</div>\n                        <i class=\"locked fa fa-lock tippy-zulip-tooltip\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":73},"end":{"line":61,"column":118}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Only organization administrators can edit these settings.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":139},"end":{"line":61,"column":205}}}))
    + "\"></i>\n                    </li>\n                    <li class=\"sidebar-item\" class=\"collapse-org-settings "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":74},"end":{"line":63,"column":122}}})) != null ? stack1 : "")
    + "\" tabindex=\"0\" data-section=\"organization-settings\">\n                        <i class=\"sidebar-item-icon fa fa-sliders\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":65,"column":42},"end":{"line":65,"column":72}}}))
    + "</div>\n                        <i class=\"locked fa fa-lock tippy-zulip-tooltip\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":73},"end":{"line":66,"column":118}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Only organization administrators can edit these settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":139},"end":{"line":66,"column":204}}}))
    + "\"></i>\n                    </li>\n                    <li class=\"sidebar-item\" class=\"collapse-org-settings "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":74},"end":{"line":68,"column":122}}})) != null ? stack1 : "")
    + "\" tabindex=\"0\" data-section=\"organization-permissions\">\n                        <i class=\"sidebar-item-icon fa fa-lock\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization permissions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":70,"column":42},"end":{"line":70,"column":75}}}))
    + "</div>\n                        <i class=\"locked fa fa-lock tippy-zulip-tooltip\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":73},"end":{"line":71,"column":118}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Only organization administrators can edit these settings.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":139},"end":{"line":71,"column":205}}}))
    + "\"></i>\n                    </li>\n                    <li class=\"sidebar-item\" tabindex=\"0\" data-section=\"emoji-settings\">\n                        <i class=\"sidebar-item-icon fa fa-smile-o\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Custom emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":75,"column":42},"end":{"line":75,"column":63}}}))
    + "</div>\n                        <i class=\"locked fa fa-lock tippy-zulip-tooltip\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"show_emoji_settings_lock"),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":73},"end":{"line":76,"column":142}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You do not have permission to add custom emoji.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":76,"column":163},"end":{"line":76,"column":218}}}))
    + "\"></i>\n                    </li>\n                    <li class=\"sidebar-item\" tabindex=\"0\" data-section=\"linkifier-settings\">\n                        <i class=\"sidebar-item-icon fa fa-font\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Linkifiers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":80,"column":42},"end":{"line":80,"column":61}}}))
    + "</div>\n                        <i class=\"locked fa fa-lock tippy-zulip-tooltip\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":73},"end":{"line":81,"column":118}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Only organization administrators can edit these settings.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":81,"column":139},"end":{"line":81,"column":205}}}))
    + "\"></i>\n                    </li>\n                    <li class=\"sidebar-item\" tabindex=\"0\" data-section=\"playground-settings\">\n                        <i class=\"sidebar-item-icon fa fa-external-link\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Code playgrounds",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":42},"end":{"line":85,"column":67}}}))
    + "</div>\n                        <i class=\"locked fa fa-lock tippy-zulip-tooltip\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":73},"end":{"line":86,"column":118}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Only organization administrators can edit these settings.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":86,"column":139},"end":{"line":86,"column":205}}}))
    + "\"></i>\n                    </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_guest"),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":20},"end":{"line":94,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_guest"),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":95,"column":20},"end":{"line":101,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":20},"end":{"line":107,"column":27}}})) != null ? stack1 : "")
    + "                    <li class=\"sidebar-item collapse-org-settings "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":66},"end":{"line":108,"column":114}}})) != null ? stack1 : "")
    + "\" tabindex=\"0\" data-section=\"organization-level-user-defaults\">\n                        <i class=\"sidebar-item-icon fa fa-cog\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Default user settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":110,"column":42},"end":{"line":110,"column":72}}}))
    + "</div>\n                        <i class=\"locked fa fa-lock tippy-zulip-tooltip\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":111,"column":73},"end":{"line":111,"column":118}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Only organization administrators can edit these settings.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":111,"column":139},"end":{"line":111,"column":205}}}))
    + "\"></i>\n                    </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_guest"),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":113,"column":20},"end":{"line":121,"column":31}}})) != null ? stack1 : "")
    + "                    <li class=\"sidebar-item collapse-org-settings "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":122,"column":66},"end":{"line":122,"column":114}}})) != null ? stack1 : "")
    + "\" tabindex=\"0\" data-section=\"auth-methods\">\n                        <i class=\"sidebar-item-icon fa fa-key\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Authentication methods",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":124,"column":42},"end":{"line":124,"column":73}}}))
    + "</div>\n                        <i class=\"locked fa fa-lock tippy-zulip-tooltip\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_owner"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":125,"column":73},"end":{"line":125,"column":118}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Only organization owners can edit these settings.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":125,"column":139},"end":{"line":125,"column":197}}}))
    + "\"></i>\n                    </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":127,"column":20},"end":{"line":132,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"unless","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":133,"column":20},"end":{"line":138,"column":31}}})) != null ? stack1 : "")
    + "                </ul>\n            </div>\n        </div>\n    </div>\n    <div class=\"content-wrapper right\">\n        <div class=\"settings-header\">\n            <h1>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":145,"column":16},"end":{"line":145,"column":33}}}))
    + "<span class=\"section\"></span></h1>\n            <div class=\"exit\">\n                <span class=\"exit-sign\">&times;</span>\n            </div>\n        </div>\n        <div id=\"settings_content\" data-simplebar data-simplebar-tab-index=\"-1\" data-simplebar-auto-hide=\"false\">\n            <div class=\"organization-box organization\">\n\n            </div>\n            <div class=\"settings-box\">\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});