var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"check checked join_leave_button tippy-zulip-tooltip "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"can_leave"),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":72},"end":{"line":3,"column":112}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_direct_member"),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":113},"end":{"line":3,"column":169}}})) != null ? stack1 : "")
    + "\" data-tooltip-template-id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_leave"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":3,"column":197},"end":{"line":3,"column":407}}})) != null ? stack1 : "")
    + "\">\n            <template id=\"leave-"
    + alias4(alias3(alias2(depth0, "name", {"start":{"line":4,"column":34},"end":{"line":4,"column":38}} ), depth0))
    + "-group-tooltip-template\">\n                <span>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":8,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n            </template>\n\n            <template id=\"cannot-leave-"
    + alias4(alias3(alias2(depth0, "name", {"start":{"line":12,"column":41},"end":{"line":12,"column":45}} ), depth0))
    + "-because-of-subgroup-tooltip-template\">\n                <span>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":16,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n            </template>\n\n            <template id=\"cannot-leave-"
    + alias4(alias3(alias2(depth0, "name", {"start":{"line":20,"column":41},"end":{"line":20,"column":45}} ), depth0))
    + "-group-tooltip-template\">\n                <span>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":24,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n            </template>\n\n            <svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" width=\"100%\" height=\"100%\" viewBox=\"0 0 512 512\" style=\"enable-background:new 0 0 512 512;\" xml:space=\"preserve\">\n                <path d=\"M448,71.9c-17.3-13.4-41.5-9.3-54.1,9.1L214,344.2l-99.1-107.3c-14.6-16.6-39.1-17.4-54.7-1.8 c-15.6,15.5-16.4,41.6-1.7,58.1c0,0,120.4,133.6,137.7,147c17.3,13.4,41.5,9.3,54.1-9.1l206.3-301.7 C469.2,110.9,465.3,85.2,448,71.9z\"/>\n            </svg>\n            <div class='join_leave_status'></div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"4":function(container,depth0,helpers,partials,data) {
    return "not-direct-member";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_direct_member"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":3,"column":214},"end":{"line":3,"column":348}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "leave-"
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":3,"column":246},"end":{"line":3,"column":250}} ), depth0))
    + "-group-tooltip-template";
},"9":function(container,depth0,helpers,partials,data) {
    return "cannot-leave-"
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":3,"column":298},"end":{"line":3,"column":302}} ), depth0))
    + "-because-of-subgroup-tooltip-template";
},"11":function(container,depth0,helpers,partials,data) {
    return "cannot-leave-"
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":3,"column":371},"end":{"line":3,"column":375}} ), depth0))
    + "-group-tooltip-template";
},"13":function(container,depth0,helpers,partials,data) {
    return "                        Leave group {name}\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                        You are a member of this group because you are a member of a subgroup (<b>{associated_subgroup_names}</b>).\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "                        You do not have permission to leave this group.\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"check join_leave_button "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"can_join"),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":44},"end":{"line":34,"column":83}}})) != null ? stack1 : "")
    + " tippy-zulip-tooltip\" data-tooltip-template-id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_join"),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":34,"column":131},"end":{"line":34,"column":241}}})) != null ? stack1 : "")
    + "\">\n            <template id=\"join-"
    + alias4(alias3(alias2(depth0, "name", {"start":{"line":35,"column":33},"end":{"line":35,"column":37}} ), depth0))
    + "-group-tooltip-template\">\n                <span>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":20},"end":{"line":39,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n            </template>\n\n            <template id=\"cannot-join-"
    + alias4(alias3(alias2(depth0, "name", {"start":{"line":43,"column":40},"end":{"line":43,"column":44}} ), depth0))
    + "-group-tooltip-template\">\n                <span>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":20},"end":{"line":47,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n            </template>\n\n            <svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" width=\"100%\" height=\"100%\" viewBox=\"0 0 512 512\" style=\"enable-background:new 0 0 512 512;\" xml:space=\"preserve\">\n                <path d=\"M459.319,229.668c0,22.201-17.992,40.193-40.205,40.193H269.85v149.271c0,22.207-17.998,40.199-40.196,40.193   c-11.101,0-21.149-4.492-28.416-11.763c-7.276-7.281-11.774-17.324-11.769-28.419l-0.006-149.288H40.181   c-11.094,0-21.134-4.492-28.416-11.774c-7.264-7.264-11.759-17.312-11.759-28.413C0,207.471,17.992,189.475,40.202,189.475h149.267   V40.202C189.469,17.998,207.471,0,229.671,0c22.192,0.006,40.178,17.986,40.19,40.187v149.288h149.282   C441.339,189.487,459.308,207.471,459.319,229.668z\"/>\n            </svg>\n            <div class='join_leave_status'></div>\n        </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "join-"
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":34,"column":154},"end":{"line":34,"column":158}} ), depth0))
    + "-group-tooltip-template";
},"22":function(container,depth0,helpers,partials,data) {
    return "cannot-join-"
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":34,"column":205},"end":{"line":34,"column":209}} ), depth0))
    + "-group-tooltip-template";
},"24":function(container,depth0,helpers,partials,data) {
    return "                        Join group {name}\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "                        You do not have permission to join this group.\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"group-row\" data-group-id=\""
    + alias3(alias2(alias1(depth0, "id", {"start":{"line":1,"column":40},"end":{"line":1,"column":42}} ), depth0))
    + "\" data-group-name=\""
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":1,"column":65},"end":{"line":1,"column":69}} ), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_member"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":56,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"group-info-box\">\n        <div class=\"top-bar\">\n            <div class=\"group-name\">"
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":59,"column":38},"end":{"line":59,"column":42}} ), depth0))
    + "</div>\n        </div>\n        <div class=\"bottom-bar\">\n            <div class=\"description rendered_markdown\" data-no-description=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"No description.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":76},"end":{"line":62,"column":99}}}))
    + "\">"
    + alias3(alias2(alias1(depth0, "description", {"start":{"line":62,"column":103},"end":{"line":62,"column":114}} ), depth0))
    + "</div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});