var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <a class=\"above_compose_banner_action_link\" "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"above_composebox_narrow_url"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":70},"end":{"line":4,"column":150}}})) != null ? stack1 : "")
    + " data-message-id=\""
    + alias3(alias2(alias1(depth0, "link_msg_id", {"start":{"line":4,"column":170},"end":{"line":4,"column":181}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "link_text", {"start":{"line":4,"column":187},"end":{"line":4,"column":196}} ), depth0))
    + "</a>";
},"2":function(container,depth0,helpers,partials,data) {
    return "href=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "above_composebox_narrow_url", {"start":{"line":4,"column":113},"end":{"line":4,"column":140}} ), depth0))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"above_compose_banner main-view-banner success "
    + alias3(alias2(alias1(depth0, "classname", {"start":{"line":1,"column":60},"end":{"line":1,"column":69}} ), depth0))
    + "\">\n    <p class=\"banner_content\">\n        "
    + alias3(alias2(alias1(depth0, "banner_text", {"start":{"line":3,"column":10},"end":{"line":3,"column":21}} ), depth0))
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"link_text"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":209}}})) != null ? stack1 : "")
    + "\n    </p>\n    <a role=\"button\" class=\"zulip-icon zulip-icon-close main-view-banner-close-button\"></a>\n</div>\n";
},"useData":true});