var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            "
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":4,"column":14},"end":{"line":4,"column":18}} ), depth0))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "            <a class=\"group_list_item_link\" href=\""
    + alias3(alias2(alias1(depth0, "group_edit_url", {"start":{"line":6,"column":52},"end":{"line":6,"column":66}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":6,"column":72},"end":{"line":6,"column":76}} ), depth0))
    + "</a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"remove_member\">\n        <div class=\"group_list_remove\">\n            <span "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_direct_member"),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":18},"end":{"line":12,"column":327}}})) != null ? stack1 : "")
    + ">\n                <button type=\"button\" name=\"remove\" class=\"remove-member-button button small rounded button-danger\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_direct_member"),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":116},"end":{"line":13,"column":174}}})) != null ? stack1 : "")
    + " >\n                    "
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Remove",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":14,"column":35}}}))
    + "\n                </button>\n            </span>\n        </div>\n    </td>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "class=\"tippy-zulip-tooltip\" data-tippy-content=\""
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_me"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":12,"column":94},"end":{"line":12,"column":315}}})) != null ? stack1 : "")
    + "\"";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"You are a member of {name} because you are a member of a subgroup ({subgroups_name}).",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":107},"end":{"line":12,"column":200}}}))
    + " ";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"This user is a member of {name} because they are a member of a subgroup ({subgroups_name}).",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":209},"end":{"line":12,"column":308}}}));
},"11":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr data-group-id=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "group_id", {"start":{"line":1,"column":21},"end":{"line":1,"column":29}} ), depth0))
    + "\">\n    <td class=\"group_list_item\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_guest"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_remove_members"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":19,"column":11}}})) != null ? stack1 : "")
    + "</tr>\n";
},"useData":true});