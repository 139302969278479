var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "<strong>\n    <span class=\"stream\">"
    + alias3(alias2(alias1(depth0, "stream_name", {"start":{"line":2,"column":27},"end":{"line":2,"column":38}} ), depth0))
    + "</span> &gt; <span class=\"topic white-space-preserve-wrap\">"
    + alias3(alias2(alias1(depth0, "topic", {"start":{"line":2,"column":101},"end":{"line":2,"column":106}} ), depth0))
    + "</span>\n</strong>\n";
},"useData":true});