var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                    <option value='"
    + alias3(alias2(alias1(depth0, "id", {"start":{"line":7,"column":37},"end":{"line":7,"column":44}} ), depth0))
    + "'>"
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":7,"column":50},"end":{"line":7,"column":59}} ), depth0))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                    <option value='"
    + alias3(alias2(alias1(data, "key", {"start":{"line":15,"column":37},"end":{"line":15,"column":41}} ), depth0))
    + "'>"
    + alias3(alias2(alias1(depth0, "text", {"start":{"line":15,"column":47},"end":{"line":15,"column":56}} ), depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"admin-profile-field-form\" id=\"add-new-custom-profile-field-form\">\n    <div class=\"new-profile-field-form wrapper\">\n        <div class=\"input-group\">\n            <label for=\"profile_field_type\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Type",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":70},"end":{"line":4,"column":83}}}))
    + "</label>\n            <select id=\"profile_field_type\" name=\"field_type\" class=\"modal_select bootstrap-focus-style\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"custom_profile_field_types"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":8,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n        </div>\n        <div class=\"input-group\" id=\"profile_field_external_accounts\">\n            <label for=\"profile_field_external_accounts_type\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"External account type",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":88},"end":{"line":12,"column":118}}}))
    + "</label>\n            <select id=\"profile_field_external_accounts_type\" name=\"external_acc_field_type\" class=\"modal_select bootstrap-focus-style\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"realm_default_external_accounts"),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":16,"column":25}}})) != null ? stack1 : "")
    + "                <option value=\"custom\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Custom",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":39},"end":{"line":17,"column":54}}}))
    + "</option>\n            </select>\n        </div>\n        <div class=\"input-group\">\n            <label for=\"profile_field_name\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Label",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":70},"end":{"line":21,"column":84}}}))
    + "</label>\n            <input type=\"text\" id=\"profile_field_name\" class=\"modal_text_input\" name=\"name\" autocomplete=\"off\" maxlength=\"40\" />\n        </div>\n        <div class=\"input-group\">\n            <label for=\"profile_field_hint\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Hint (up to 80 characters)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":70},"end":{"line":25,"column":105}}}))
    + "</label>\n            <input type=\"text\" id=\"profile_field_hint\" class=\"modal_text_input\" name=\"hint\" autocomplete=\"off\" maxlength=\"80\" />\n            <div class=\"alert\" id=\"admin-profile-field-hint-status\"></div>\n        </div>\n        <div class=\"input-group\" id=\"profile_field_choices_row\">\n            <label for=\"profile_field_choices\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Field choices",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":73},"end":{"line":30,"column":95}}}))
    + "</label>\n            <table class=\"profile_field_choices_table\">\n                <tbody id=\"profile_field_choices\" class=\"profile-field-choices\"></tbody>\n            </table>\n        </div>\n        <div class=\"input-group\" id=\"custom_external_account_url_pattern\">\n            <label for=\"custom_field_url_pattern\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"URL pattern",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":76},"end":{"line":36,"column":96}}}))
    + "</label>\n            <input type=\"url\" id=\"custom_field_url_pattern\" class=\"modal_url_input\" name=\"url_pattern\" autocomplete=\"off\" maxlength=\"1024\" placeholder=\"https://example.com/path/%(username)s\"/>\n        </div>\n        <div class=\"input-group\">\n            <label class=\"checkbox profile_field_display_label\" for=\"profile_field_display_in_profile_summary\">\n                <input type=\"checkbox\" id=\"profile_field_display_in_profile_summary\" name=\"display_in_profile_summary\"/>\n                <span class=\"rendered-checkbox\"></span>\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Display on user card",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":16},"end":{"line":43,"column":45}}}))
    + "\n            </label>\n        </div>\n        <div class=\"input-group\">\n            <label class=\"checkbox\" for=\"profile-field-required\">\n                <input type=\"checkbox\" id=\"profile-field-required\" name=\"required\"/>\n                <span class=\"rendered-checkbox\"></span>\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Required field",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":16},"end":{"line":50,"column":39}}}))
    + "\n            </label>\n        </div>\n        "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"label":lookupProperty(helpers,"t").call(alias1,"Users can edit this field for their own account",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":57,"column":16},"end":{"line":57,"column":69}}}),"is_checked":true,"setting_name":"editable_by_user","prefix":"profile_field_"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n</form>\n";
},"usePartial":true,"useData":true});