var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        When you deactivate <z-user></z-user>, they will be immediately logged out.\n        \n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<strong>"
    + container.escapeExpression(container.lambda(container.strict(depth0, "username", {"start":{"line":4,"column":39},"end":{"line":4,"column":47}} ), depth0))
    + "</strong>"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"email"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":58},"end":{"line":4,"column":96}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return " &lt;"
    + container.escapeExpression(container.lambda(container.strict(depth0, "email", {"start":{"line":4,"column":78},"end":{"line":4,"column":83}} ), depth0))
    + "&gt;";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <strong>{username}</strong> has {number_of_invites_by_user} unexpired invitations.\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"They administer the following bots:",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":13,"column":51}}}))
    + "\n        <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"bots_owned_by_user"),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":17,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "                <li>"
    + container.escapeExpression(container.lambda(container.strict(depth0, "full_name", {"start":{"line":16,"column":22},"end":{"line":16,"column":36}} ), depth0))
    + "</li>\n";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                Your Zulip account on <z-link></z-link> has been deactivated,\n                and you will no longer be able to log in.\n                \n";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "<a href=\""
    + alias3(alias2(alias1(depth0, "realm_url", {"start":{"line":36,"column":48},"end":{"line":36,"column":57}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "realm_url", {"start":{"line":36,"column":63},"end":{"line":36,"column":72}} ), depth0))
    + "</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":5,"column":11}}})) != null ? stack1 : "")
    + "</p>\n<p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Their password will be cleared from our systems, and any bots they maintain will be disabled.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":3},"end":{"line":7,"column":105}}}))
    + "</p>\n<p>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"bots_owned_by_user"),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":19,"column":11}}})) != null ? stack1 : "")
    + "</p>\n<label class=\"checkbox\">\n    <input type=\"checkbox\" class=\"send_email\" />\n    <span class=\"rendered-checkbox\"></span>\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Notify this user by email?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":4},"end":{"line":24,"column":39}}}))
    + "\n</label>\n<div class=\"email_field\">\n    <p class=\"border-top\">\n        <strong>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Subject",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":16},"end":{"line":28,"column":32}}}))
    + ":</strong>\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Notification of account deactivation on {realm_name}",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":8},"end":{"line":29,"column":69}}}))
    + "\n    </p>\n    <div class=\"email-body\">\n        <p>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":12},"end":{"line":37,"column":19}}})) != null ? stack1 : "")
    + "        </p>\n        <p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"The administrators provided the following comment:",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":11},"end":{"line":39,"column":70}}}))
    + "</p>\n        <textarea class=\"email_field_textarea settings_textarea\" rows=\"8\" maxlength=\"2000\"></textarea>\n    </div>\n</div>\n";
},"1_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-user"],"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":107}}}) || fn;
  return fn;
  }

,"useDecorators":true,"10_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":36,"column":16},"end":{"line":36,"column":89}}}) || fn;
  return fn;
  }

,"useData":true,"useDepths":true});