var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"zulip-merge-base-info\">\n                    <span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Forked from upstream at {zulip_merge_base}",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":26},"end":{"line":18,"column":77}}}))
    + "</span>\n                    <span class=\"copy-button tippy-zulip-tooltip zulip-merge-base\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Copy version",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":103},"end":{"line":19,"column":124}}}))
    + "\" data-tippy-placement=\"right\" data-clipboard-text=\""
    + alias2(container.lambda(container.strict(depth0, "zulip_merge_base", {"start":{"line":19,"column":178},"end":{"line":19,"column":194}} ), depth0))
    + "\">\n                        <i class=\"zulip-icon zulip-icon-copy\" aria-hidden=\"true\"></i>\n                    </span>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"about-zulip\" class=\"overlay flex\" tabindex=\"-1\" role=\"dialog\" data-overlay=\"about-zulip\" aria-hidden=\"true\">\n    <div class=\"overlay-content overlay-container\">\n        <button type=\"button\" class=\"exit\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Close",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":55},"end":{"line":3,"column":69}}}))
    + "\"><span aria-hidden=\"true\">&times;</span></button>\n        <div class=\"overlay-body\">\n            <div class=\"about-zulip-logo\">\n                <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://zulip.com\"><img src=\"" + require("../../static/images/logo/zulip-org-logo.svg") + "\" alt=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Zulip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":146},"end":{"line":6,"column":160}}}))
    + "\" /></a>\n            </div>\n            <div class=\"about-zulip-version\">\n                <strong>Zulip Server</strong>\n                <div class=\"zulip-version-info\">\n                    <span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Version {zulip_version}",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":26},"end":{"line":11,"column":58}}}))
    + "</span>\n                    <span class=\"copy-button tippy-zulip-tooltip zulip-version\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Copy version",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":100},"end":{"line":12,"column":121}}}))
    + "\" data-tippy-placement=\"right\" data-clipboard-text=\""
    + alias2(container.lambda(container.strict(depth0, "zulip_version", {"start":{"line":12,"column":175},"end":{"line":12,"column":188}} ), depth0))
    + "\">\n                        <i class=\"zulip-icon zulip-icon-copy\" aria-hidden=\"true\"></i>\n                    </span>\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_fork"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":23,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n            <p>\n                Copyright 2012–2015 Dropbox, Inc., 2015–2021 Kandra Labs, Inc., and contributors.\n            </p>\n            <p>\n                Zulip is <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://github.com/zulip/zulip#readme\">open-source software</a>,\n                distributed under the Apache 2.0 license.\n            </p>\n        </div>\n    </div>\n</div>\n";
},"useData":true});