var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a id=\"show_realm_domains_modal\" role=\"button\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"[Configure]",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":71},"end":{"line":32,"column":91}}}))
    + "</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                            <option value=\""
    + alias3(alias2(alias1(depth0, "value", {"start":{"line":160,"column":45},"end":{"line":160,"column":50}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "text", {"start":{"line":160,"column":56},"end":{"line":160,"column":60}} ), depth0))
    + "</option>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                        <option value=\""
    + alias3(alias2(alias1(depth0, "value", {"start":{"line":194,"column":41},"end":{"line":194,"column":46}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "text", {"start":{"line":194,"column":52},"end":{"line":194,"column":56}} ), depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"organization-permissions\" data-name=\"organization-permissions\" class=\"settings-section\">\n    <form class=\"admin-realm-form org-permissions-form\">\n\n        <div id=\"org-join-settings\" class=\"settings-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Joining the organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":53}}}))
    + "\n                    <i class=\"fa fa-info-circle settings-info-icon tippy-zulip-tooltip\" aria-hidden=\"true\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Only owners can change these settings.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":127},"end":{"line":8,"column":174}}}))
    + "\"></i>\n                </h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),undefined,{"name":"settings_save_discard_widget","hash":{"section_name":"join-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"m-10 inline-block organization-permissions-parent\">\n\n                "
    + ((stack1 = container.invokePartial(require("./group_setting_value_pill_input.hbs"),undefined,{"name":"group_setting_value_pill_input","hash":{"label":lookupProperty(helpers,"t").call(alias1,"Who can send email invitations to new users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":24},"end":{"line":16,"column":73}}}),"setting_name":"realm_can_invite_users_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = container.invokePartial(require("./group_setting_value_pill_input.hbs"),undefined,{"name":"group_setting_value_pill_input","hash":{"label":lookupProperty(helpers,"t").call(alias1,"Who can create reusable invitation links",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":24},"end":{"line":20,"column":70}}}),"setting_name":"realm_create_multiuse_invite_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                <div class=\"input-group\">\n                    <label for=\"id_realm_org_join_restrictions\" class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Restrict email domains of new users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":93},"end":{"line":23,"column":137}}}))
    + "</label>\n                    <select name=\"realm_org_join_restrictions\" id=\"id_realm_org_join_restrictions\" class=\"prop-element settings_select bootstrap-focus-style\" data-setting-widget-type=\"string\">\n                        <option value=\"no_restriction\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"No restrictions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":55},"end":{"line":25,"column":79}}}))
    + "</option>\n                        <option value=\"no_disposable_email\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Don’t allow disposable email addresses",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":60},"end":{"line":26,"column":107}}}))
    + "</option>\n                        <option value=\"only_selected_domain\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Restrict to a list of domains",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":61},"end":{"line":27,"column":99}}}))
    + "</option>\n                    </select>\n                    <div class=\"dependent-settings-block\">\n                        <p id=\"allowed_domains_label\" class=\"inline-block\"></p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_owner"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":24},"end":{"line":33,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n                <div class=\"input-group time-limit-setting\">\n                    <label for=\"id_realm_waiting_period_threshold\" class=\"settings-field-label\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Waiting period before new members turn into full members",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":24},"end":{"line":38,"column":89}}}))
    + "\n                        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/restrict-permissions-of-new-members"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </label>\n                    <select name=\"realm_waiting_period_threshold\" id=\"id_realm_waiting_period_threshold\" class=\"prop-element settings_select bootstrap-focus-style\" data-setting-widget-type=\"time-limit\">\n                        "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),undefined,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"waiting_period_threshold_dropdown_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </select>\n                    <div class=\"dependent-settings-block\">\n                        <label for=\"id_realm_waiting_period_threshold_custom_input\" class=\"inline-block\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Waiting period (days)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":105},"end":{"line":46,"column":135}}}))
    + ":</label>\n                        <input type=\"text\" id=\"id_realm_waiting_period_threshold_custom_input\"\n                          name=\"realm_waiting_period_threshold_custom_input\"\n                          class=\"time-limit-custom-input\"\n                          value=\""
    + alias2(alias4(alias3(depth0, "realm_waiting_period_threshold", {"start":{"line":50,"column":36},"end":{"line":50,"column":66}} ), depth0))
    + "\"/>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"org-stream-permissions\" class=\"settings-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Channel permissions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":20},"end":{"line":58,"column":48}}}))
    + "</h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),undefined,{"name":"settings_save_discard_widget","hash":{"section_name":"stream-permissions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"m-10 inline-block organization-permissions-parent\">\n                "
    + ((stack1 = container.invokePartial(require("./group_setting_value_pill_input.hbs"),undefined,{"name":"group_setting_value_pill_input","hash":{"label":lookupProperty(helpers,"t").call(alias1,"Who can create public channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":64,"column":24},"end":{"line":64,"column":60}}}),"setting_name":"realm_can_create_public_channel_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = container.invokePartial(require("./upgrade_tip_widget.hbs"),depth0,{"name":"upgrade_tip_widget","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"help_link":"/help/public-access-option","is_disabled":lookupProperty(depth0,"disable_enable_spectator_access_setting"),"label":lookupProperty(lookupProperty(depth0,"admin_settings_label"),"realm_enable_spectator_access"),"is_checked":lookupProperty(depth0,"realm_enable_spectator_access"),"prefix":"id_","setting_name":"realm_enable_spectator_access"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = container.invokePartial(require("../dropdown_widget_with_label.hbs"),undefined,{"name":"../dropdown_widget_with_label","hash":{"value_type":"number","label":lookupProperty(helpers,"t").call(alias1,"Who can create web-public channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":77,"column":24},"end":{"line":77,"column":64}}}),"widget_name":"realm_can_create_web_public_channel_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = container.invokePartial(require("./group_setting_value_pill_input.hbs"),undefined,{"name":"group_setting_value_pill_input","hash":{"label":lookupProperty(helpers,"t").call(alias1,"Who can create private channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":82,"column":24},"end":{"line":82,"column":61}}}),"setting_name":"realm_can_create_private_channel_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                <div class=\"input-group\">\n                    <label for=\"id_realm_invite_to_stream_policy\" class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can add users to channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":95},"end":{"line":85,"column":133}}}))
    + "</label>\n                    <select name=\"realm_invite_to_stream_policy\" id=\"id_realm_invite_to_stream_policy\" class=\"prop-element settings_select bootstrap-focus-style\" data-setting-widget-type=\"number\">\n                        "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),undefined,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"common_policy_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n                <div class=\"input-group\">\n                    <label for=\"id_realm_wildcard_mention_policy\" class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can notify a large number of users with a wildcard mention",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":91,"column":95},"end":{"line":91,"column":166}}}))
    + "\n                        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/restrict-wildcard-mentions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </label>\n                    <select name=\"realm_wildcard_mention_policy\" id=\"id_realm_wildcard_mention_policy\" class=\"prop-element settings_select bootstrap-focus-style\" data-setting-widget-type=\"number\">\n                        "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),undefined,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"wildcard_mention_policy_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"org-group-permissions\" class=\"settings-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Group permissions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":103,"column":20},"end":{"line":103,"column":46}}}))
    + "\n                    "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/manage-user-groups"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),undefined,{"name":"settings_save_discard_widget","hash":{"section_name":"group-permissions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"m-10 inline-block organization-permissions-parent\">\n                "
    + ((stack1 = container.invokePartial(require("./group_setting_value_pill_input.hbs"),undefined,{"name":"group_setting_value_pill_input","hash":{"label":lookupProperty(helpers,"t").call(alias1,"Who can administer all user groups",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":111,"column":24},"end":{"line":111,"column":64}}}),"setting_name":"realm_can_manage_all_groups"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                "
    + ((stack1 = container.invokePartial(require("./upgrade_tip_widget.hbs"),depth0,{"name":"upgrade_tip_widget","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                "
    + ((stack1 = container.invokePartial(require("./group_setting_value_pill_input.hbs"),undefined,{"name":"group_setting_value_pill_input","hash":{"label":lookupProperty(helpers,"t").call(alias1,"Who can create user groups",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":115,"column":24},"end":{"line":115,"column":56}}}),"setting_name":"realm_can_create_groups"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <div id=\"org-direct-message-permissions\" class=\"settings-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Direct message permissions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":121,"column":20},"end":{"line":121,"column":55}}}))
    + "\n                    "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/restrict-direct-messages"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),undefined,{"name":"settings_save_discard_widget","hash":{"section_name":"direct-message-permissions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n\n            "
    + ((stack1 = container.invokePartial(require("./group_setting_value_pill_input.hbs"),undefined,{"name":"group_setting_value_pill_input","hash":{"label":lookupProperty(helpers,"t").call(alias1,"Who can authorize a direct message conversation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":129,"column":20},"end":{"line":129,"column":73}}}),"setting_name":"realm_direct_message_permission_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = container.invokePartial(require("./group_setting_value_pill_input.hbs"),undefined,{"name":"group_setting_value_pill_input","hash":{"label":lookupProperty(helpers,"t").call(alias1,"Who can start a direct message conversation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":133,"column":20},"end":{"line":133,"column":69}}}),"setting_name":"realm_direct_message_initiator_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n\n        <div id=\"org-msg-editing\" class=\"settings-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message editing",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":138,"column":20},"end":{"line":138,"column":44}}}))
    + "\n                    "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/restrict-message-editing-and-deletion"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),undefined,{"name":"settings_save_discard_widget","hash":{"section_name":"msg-editing"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"inline-block organization-settings-parent\">\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"label":lookupProperty(lookupProperty(depth0,"admin_settings_label"),"realm_allow_message_editing"),"is_checked":lookupProperty(depth0,"realm_allow_message_editing"),"prefix":"id_","setting_name":"realm_allow_message_editing"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"label":lookupProperty(lookupProperty(depth0,"admin_settings_label"),"realm_allow_edit_history"),"is_checked":lookupProperty(depth0,"realm_allow_edit_history"),"prefix":"id_","setting_name":"realm_allow_edit_history"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                <div class=\"input-group time-limit-setting\">\n                    <label for=\"id_realm_message_content_edit_limit_seconds\" class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Time limit for editing messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":157,"column":106},"end":{"line":157,"column":146}}}))
    + "</label>\n                    <select name=\"realm_message_content_edit_limit_seconds\" id=\"id_realm_message_content_edit_limit_seconds\" class=\"prop-element settings_select bootstrap-focus-style\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"realm_allow_message_editing"),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":158,"column":184},"end":{"line":158,"column":242}}})) != null ? stack1 : "")
    + " data-setting-widget-type=\"time-limit\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"msg_edit_limit_dropdown_values"),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":159,"column":24},"end":{"line":161,"column":33}}})) != null ? stack1 : "")
    + "                    </select>\n                    <div class=\"dependent-settings-block\">\n                        <label for=\"id_realm_message_content_edit_limit_minutes\" class=\"inline-block realm-time-limit-label\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Duration editing is allowed after posting (minutes)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":165,"column":28},"end":{"line":165,"column":87}}}))
    + ":&nbsp;\n                        </label>\n                        <input type=\"text\" id=\"id_realm_message_content_edit_limit_minutes\"\n                          name=\"realm_message_content_edit_limit_minutes\"\n                          class=\"time-limit-custom-input\"\n                          autocomplete=\"off\"\n                          value=\""
    + alias2(alias4(alias3(depth0, "realm_message_content_edit_limit_minutes", {"start":{"line":171,"column":36},"end":{"line":171,"column":76}} ), depth0))
    + "\"\n                          "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"realm_allow_message_editing"),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":172,"column":26},"end":{"line":172,"column":84}}})) != null ? stack1 : "")
    + "/>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"org-moving-msgs\" class=\"settings-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Moving messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":180,"column":20},"end":{"line":180,"column":44}}}))
    + "\n                    "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/restrict-moving-messages"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),undefined,{"name":"settings_save_discard_widget","hash":{"section_name":"moving-msgs"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n\n            "
    + ((stack1 = container.invokePartial(require("./group_setting_value_pill_input.hbs"),undefined,{"name":"group_setting_value_pill_input","hash":{"label":lookupProperty(helpers,"t").call(alias1,"Who can move messages to another topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":188,"column":20},"end":{"line":188,"column":64}}}),"setting_name":"realm_can_move_messages_between_topics_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n            <div class=\"input-group time-limit-setting\">\n                <label for=\"id_realm_move_messages_within_stream_limit_seconds\" class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Time limit for editing topics",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":191,"column":109},"end":{"line":191,"column":147}}}))
    + " <i>("
    + alias2(lookupProperty(helpers,"t").call(alias1,"does not apply to moderators and administrators",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":191,"column":152},"end":{"line":191,"column":208}}}))
    + ")</i></label>\n                <select name=\"realm_move_messages_within_stream_limit_seconds\" id=\"id_realm_move_messages_within_stream_limit_seconds\" class=\"prop-element settings_select\" data-setting-widget-type=\"time-limit\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"msg_move_limit_dropdown_values"),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":193,"column":20},"end":{"line":195,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n                <div class=\"dependent-settings-block\">\n                    <label for=\"id_realm_move_messages_within_stream_limit_minutes\" class=\"inline-block realm-time-limit-label\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Duration editing is allowed after posting (minutes)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":199,"column":24},"end":{"line":199,"column":83}}}))
    + ":&nbsp;\n                    </label>\n                    <input type=\"text\" id=\"id_realm_move_messages_within_stream_limit_minutes\"\n                      name=\"realm_move_messages_within_stream_limit_minutes\"\n                      class=\"time-limit-custom-input\"\n                      autocomplete=\"off\"/>\n                </div>\n            </div>\n\n            "
    + ((stack1 = container.invokePartial(require("./group_setting_value_pill_input.hbs"),undefined,{"name":"group_setting_value_pill_input","hash":{"label":lookupProperty(helpers,"t").call(alias1,"Who can move messages to another channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":210,"column":20},"end":{"line":210,"column":66}}}),"setting_name":"realm_can_move_messages_between_channels_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n            <div class=\"input-group time-limit-setting\">\n                <label for=\"id_realm_move_messages_between_streams_limit_seconds\" class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Time limit for moving messages between channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":213,"column":111},"end":{"line":213,"column":167}}}))
    + " <i>("
    + alias2(lookupProperty(helpers,"t").call(alias1,"does not apply to moderators and administrators",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":213,"column":172},"end":{"line":213,"column":228}}}))
    + ")</i></label>\n                <select name=\"realm_move_messages_between_streams_limit_seconds\" id=\"id_realm_move_messages_between_streams_limit_seconds\" class=\"prop-element bootstrap-focus-style settings_select\" data-setting-widget-type=\"time-limit\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"msg_move_limit_dropdown_values"),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":215,"column":20},"end":{"line":217,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n                <div class=\"dependent-settings-block\">\n                    <label for=\"id_realm_move_messages_between_streams_limit_minutes\" class=\"inline-block realm-time-limit-label\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Duration editing is allowed after posting (minutes)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":221,"column":24},"end":{"line":221,"column":83}}}))
    + ":&nbsp;\n                    </label>\n                    <input type=\"text\" id=\"id_realm_move_messages_between_streams_limit_minutes\"\n                      name=\"realm_move_messages_between_streams_limit_minutes\"\n                      class=\"time-limit-custom-input\"\n                      autocomplete=\"off\"/>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"org-msg-deletion\" class=\"settings-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message deletion",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":233,"column":20},"end":{"line":233,"column":45}}}))
    + "\n                    "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/delete-a-message"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),undefined,{"name":"settings_save_discard_widget","hash":{"section_name":"msg-deletion"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"inline-block organization-settings-parent\">\n                "
    + ((stack1 = container.invokePartial(require("./group_setting_value_pill_input.hbs"),undefined,{"name":"group_setting_value_pill_input","hash":{"label":lookupProperty(helpers,"t").call(alias1,"Who can delete any message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":241,"column":24},"end":{"line":241,"column":56}}}),"setting_name":"realm_can_delete_any_message_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = container.invokePartial(require("./group_setting_value_pill_input.hbs"),undefined,{"name":"group_setting_value_pill_input","hash":{"label":lookupProperty(helpers,"t").call(alias1,"Who can delete their own messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":245,"column":24},"end":{"line":245,"column":63}}}),"setting_name":"realm_can_delete_own_message_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                <div class=\"input-group time-limit-setting\">\n                    <label for=\"id_realm_message_content_delete_limit_seconds\" class=\"settings-field-label\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Time limit for deleting messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":249,"column":24},"end":{"line":249,"column":65}}}))
    + " <i>("
    + alias2(lookupProperty(helpers,"t").call(alias1,"does not apply to users who can delete any message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":249,"column":70},"end":{"line":249,"column":129}}}))
    + ")</i>\n                    </label>\n                    <select name=\"realm_message_content_delete_limit_seconds\" id=\"id_realm_message_content_delete_limit_seconds\" class=\"prop-element bootstrap-focus-style settings_select\" data-setting-widget-type=\"time-limit\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"msg_delete_limit_dropdown_values"),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":252,"column":24},"end":{"line":254,"column":33}}})) != null ? stack1 : "")
    + "                    </select>\n                    <div class=\"dependent-settings-block\">\n                        <label for=\"id_realm_message_content_delete_limit_minutes\" class=\"inline-block realm-time-limit-label\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Duration deletion is allowed after posting (minutes)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":258,"column":28},"end":{"line":258,"column":89}}}))
    + ":\n                        </label>\n                        <input type=\"text\" id=\"id_realm_message_content_delete_limit_minutes\"\n                          name=\"realm_message_content_delete_limit_minutes\"\n                          class=\"time-limit-custom-input\"\n                          autocomplete=\"off\"\n                          value=\""
    + alias2(alias4(alias3(depth0, "realm_message_content_delete_limit_minutes", {"start":{"line":264,"column":36},"end":{"line":264,"column":78}} ), depth0))
    + "\"/>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"org-user-identity\" class=\"settings-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"User identity",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":272,"column":20},"end":{"line":272,"column":42}}}))
    + "\n                    "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/restrict-name-and-email-changes"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),undefined,{"name":"settings_save_discard_widget","hash":{"section_name":"user-identity"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"inline-block organization-permissions-parent\">\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"label":lookupProperty(lookupProperty(depth0,"admin_settings_label"),"realm_require_unique_names"),"is_checked":lookupProperty(depth0,"realm_require_unique_names"),"prefix":"id_","setting_name":"realm_require_unique_names"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"label":lookupProperty(lookupProperty(depth0,"admin_settings_label"),"realm_name_changes_disabled"),"is_checked":lookupProperty(depth0,"realm_name_changes_disabled"),"prefix":"id_","setting_name":"realm_name_changes_disabled"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"label":lookupProperty(lookupProperty(depth0,"admin_settings_label"),"realm_email_changes_disabled"),"is_checked":lookupProperty(depth0,"realm_email_changes_disabled"),"prefix":"id_","setting_name":"realm_email_changes_disabled"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"label":lookupProperty(lookupProperty(depth0,"admin_settings_label"),"realm_avatar_changes_disabled"),"is_checked":lookupProperty(depth0,"realm_avatar_changes_disabled"),"prefix":"id_","setting_name":"realm_avatar_changes_disabled"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n\n        <div id=\"org-guest-settings\" class=\"settings-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Guests",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":307,"column":20},"end":{"line":307,"column":35}}}))
    + "</h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),undefined,{"name":"settings_save_discard_widget","hash":{"section_name":"guest-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n\n            <div class=\"inline-block organization-permissions-parent\">\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"label":lookupProperty(lookupProperty(depth0,"admin_settings_label"),"realm_enable_guest_user_indicator"),"is_checked":lookupProperty(depth0,"realm_enable_guest_user_indicator"),"prefix":"id_","setting_name":"realm_enable_guest_user_indicator"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = container.invokePartial(require("../dropdown_widget_with_label.hbs"),undefined,{"name":"../dropdown_widget_with_label","hash":{"help_link":"/help/guest-users#configure-whether-guests-can-see-all-other-users","value_type":"number","label":lookupProperty(helpers,"t").call(alias1,"Who can view all other users in the organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":320,"column":24},"end":{"line":320,"column":78}}}),"widget_name":"realm_can_access_all_users_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <div id=\"org-other-permissions\" class=\"settings-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Other permissions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":328,"column":20},"end":{"line":328,"column":46}}}))
    + "</h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),undefined,{"name":"settings_save_discard_widget","hash":{"section_name":"other-permissions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"m-10 inline-block organization-permissions-parent\">\n                <div class=\"input-group\">\n                    <label for=\"id_realm_bot_creation_policy\" class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can add bots",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":333,"column":91},"end":{"line":333,"column":116}}}))
    + "</label>\n                    <select name=\"realm_bot_creation_policy\" class=\"setting-widget prop-element settings_select bootstrap-focus-style\" id=\"id_realm_bot_creation_policy\" data-setting-widget-type=\"number\">\n                        "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),undefined,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"bot_creation_policy_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n\n                "
    + ((stack1 = container.invokePartial(require("./group_setting_value_pill_input.hbs"),undefined,{"name":"group_setting_value_pill_input","hash":{"label":lookupProperty(helpers,"t").call(alias1,"Who can add custom emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":341,"column":24},"end":{"line":341,"column":54}}}),"setting_name":"realm_can_add_custom_emoji_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </form>\n</div>\n";
},"usePartial":true,"useData":true});