var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button class=\"create_user_group_button create_user_group_plus_button\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"zulip_plan_is_not_limited"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":15,"column":101},"end":{"line":15,"column":132}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":95},"end":{"line":15,"column":149}}})) != null ? stack1 : "")
    + ">\n                            <span class=\"create_button_plus_sign\">+</span>\n                        </button>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <a href=\"#groups/new\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Create a user group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":54},"end":{"line":40,"column":81}}}))
    + "</a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"settings-empty-option-text\">\n                            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"You do not have permission to create user groups.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":28},"end":{"line":59,"column":86}}}))
    + "\n                        </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"groups_overlay\" class=\"overlay\" data-overlay=\"group_subscriptions\">\n    <div class=\"flex overlay-content\">\n        <div class=\"user-groups-container overlay-container\">\n            <div class=\"user-groups-header\">\n                <div class=\"fa fa-chevron-left\"></div>\n                <span class=\"user-groups-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"User groups",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":48},"end":{"line":6,"column":68}}}))
    + "</span>\n                <div class=\"exit\">\n                    <span class=\"exit-sign\">&times;</span>\n                </div>\n            </div>\n            <div class=\"left\">\n                <div class=\"list-toggler-container\">\n                    <div id=\"add_new_user_group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_create_user_groups"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":18,"column":31}}})) != null ? stack1 : "")
    + "                        <div class=\"float-clear\"></div>\n                    </div>\n                </div>\n                <div class=\"input-append group_name_search_section\" id=\"group_filter\">\n                    <input type=\"text\" name=\"group_name\" id=\"search_group_name\" class=\"filter_text_input\" autocomplete=\"off\"\n                      placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter groups",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":35},"end":{"line":24,"column":57}}}))
    + "\" value=\"\"/>\n                    <button type=\"button\" class=\"bootstrap-btn clear_search_button\" id=\"clear_search_group_name\">\n                        <i class=\"fa fa-remove\" aria-hidden=\"true\"></i>\n                    </button>\n                </div>\n                <div class=\"no-groups-to-show\">\n                    <div class=\"your_groups_tab_empty_text\">\n                        <span class=\"settings-empty-option-text\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"You are not a member of any user groups.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":28},"end":{"line":32,"column":76}}}))
    + "\n                            <a href=\"#groups/all\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"View all user groups",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":50},"end":{"line":33,"column":78}}}))
    + "</a>\n                        </span>\n                    </div>\n                    <div class=\"all_groups_tab_empty_text\">\n                        <span class=\"settings-empty-option-text\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"There are no user groups you can view in this organization.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":28},"end":{"line":38,"column":95}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_create_user_groups"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":28},"end":{"line":41,"column":35}}})) != null ? stack1 : "")
    + "                        </span>\n                    </div>\n                </div>\n                <div class=\"user-groups-list\" data-simplebar data-simplebar-tab-index=\"-1\">\n                </div>\n            </div>\n            <div class=\"right\">\n                <div class=\"display-type\">\n                    <div id=\"user_group_settings_title\" class=\"user-group-info-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":86},"end":{"line":50,"column":114}}}))
    + "</div>\n                </div>\n                <div class=\"nothing-selected\">\n                    <div class=\"group-info-banner\"></div>\n                    <div class=\"create-group-button-container\">\n                        "
    + ((stack1 = container.invokePartial(require("../settings/upgrade_tip_widget.hbs"),depth0,{"name":"../settings/upgrade_tip_widget","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        <button type=\"button\" class=\"create_user_group_button animated-purple-button\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"can_create_user_groups"),lookupProperty(depth0,"zulip_plan_is_not_limited"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":56,"column":112},"end":{"line":56,"column":166}}}),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":102},"end":{"line":56,"column":187}}})) != null ? stack1 : "")
    + ">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Create user group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":188},"end":{"line":56,"column":214}}}))
    + "</button>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"can_create_user_groups"),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":24},"end":{"line":61,"column":35}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n                <div id=\"user_group_settings\" class=\"settings\" data-simplebar data-simplebar-tab-index=\"-1\" data-simplebar-auto-hide=\"false\">\n                </div>\n                "
    + ((stack1 = container.invokePartial(require("./user_group_creation_form.hbs"),depth0,{"name":"user_group_creation_form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});